import React from 'react';
import { node, string, bool } from 'prop-types';
import classNames from 'classnames';
import { Field } from 'react-final-form';

import css from './FieldCheckbox.module.css';

const IconCheckbox = props => {
  const { className, useSuccessColor } = props;
  return (
    <svg className={className} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="16" height="16" rx="2" className={classNames(css.checkedBox, { [css.success]: useSuccessColor })}/>
      <path fillRule="evenodd" clipRule="evenodd" d="M13.0568 4.69192C13.1796 4.81496 13.2486 4.98177 13.2486 5.15567C13.2486 5.32958 13.1796 5.49637 13.0568 5.61942L6.71301 11.9632C6.58996 12.086 6.42317 12.1551 6.24926 12.1551C6.07535 12.1551 5.90855 12.086 5.78551 11.9632L2.94176 9.11942C2.82584 8.99501 2.76273 8.83048 2.76573 8.66046C2.76873 8.49045 2.8376 8.32824 2.95784 8.208C3.07808 8.08776 3.24029 8.01889 3.4103 8.01589C3.58032 8.01289 3.74485 8.076 3.86926 8.19192L6.24926 10.5719L12.1293 4.69192C12.2523 4.56903 12.4191 4.5 12.593 4.5C12.7669 4.5 12.9337 4.56903 13.0568 4.69192Z" className={classNames(css.checkMark, { [css.success]: useSuccessColor })}/>
      <rect x="0.5" y="0.5" width="15" height="15" rx="1.5" className={classNames(css.box, { [css.success]: useSuccessColor })}/>
    </svg>
  );
};

IconCheckbox.defaultProps = { className: null, useSuccessColor: false };

IconCheckbox.propTypes = { className: string, useSuccessColor: bool };

const FieldCheckboxComponent = props => {
  const {
    rootClassName,
    className,
    svgClassName,
    textClassName,
    id,
    label,
    useSuccessColor,
    disabled,
    ...rest
  } = props;

  const classes = classNames(rootClassName || css.root, className);

  // This is a workaround for a bug in Firefox & React Final Form.
  // https://github.com/final-form/react-final-form/issues/134
  const handleOnChange = (input, event) => {
    const { onBlur, onChange } = input;
    onChange(event);
    onBlur(event);
  };

  return (
    <span className={classes}>
      <Field type="checkbox" {...rest}>
        {props => {
          const input = props.input;
          return (
            <input
              id={id}
              className={css.input}
              {...input}
              onChange={event => handleOnChange(input, event)}
              disabled={disabled}
            />
          );
        }}
      </Field>
      <label htmlFor={id} className={css.label}>
        <span className={css.checkboxWrapper}>
          <IconCheckbox className={svgClassName} useSuccessColor={useSuccessColor} />
        </span>
        <span className={classNames(css.text, textClassName || css.textRoot)}>{label}</span>
      </label>
    </span>
  );
};

FieldCheckboxComponent.defaultProps = {
  className: null,
  rootClassName: null,
  svgClassName: null,
  textClassName: null,
  label: null,
  disabled: false,
};

FieldCheckboxComponent.propTypes = {
  className: string,
  rootClassName: string,
  svgClassName: string,
  textClassName: string,

  // Id is needed to connect the label with input.
  id: string.isRequired,
  label: node,

  // Name groups several checkboxes to an array of selected values
  name: string.isRequired,

  // Checkbox needs a value that is passed forward when user checks the checkbox
  value: string.isRequired,
  disabled: bool.isRequired,
};

export default FieldCheckboxComponent;
