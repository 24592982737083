import React from 'react';
import AddressPickupMaybe from './AddressPickupMaybe';

import css from './TransactionPanel.module.css';

// Functional component as a helper to build detail card headings
const DetailCardHeadingsMaybe = props => {
  const {
    showDetailCardHeadings,
    listingTitle,
    subTitle,
    isPickup,
    pickupAddress
  } = props;

  return showDetailCardHeadings ? (
    <div className={css.detailCardHeadings}>
      <h2 className={css.detailCardTitle}>{listingTitle}</h2>
      <p className={css.detailCardSubtitle}>{subTitle}</p>
      <AddressPickupMaybe pickupAddress={pickupAddress} isPickup={isPickup} />
    </div>
  ) : null;
};

export default DetailCardHeadingsMaybe;
