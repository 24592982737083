import React, { useEffect } from 'react';
import { bool, func, shape, string, arrayOf } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import config from '../../config';
import { required, composeValidators, minLength, moneySubUnitAmountAtLeast, moneySubUnitAmountNoMore } from '../../util/validators';
import { propTypes, MIN_ZIP_LENGTH } from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';
import { formatMoney } from '../../util/currency';
import {
  Form,
  FieldTextInput,
  FieldCurrencyInput,
  EditListingButtonBar,
  FieldCheckbox,
  FieldSelectState,
  FieldZipInput
} from '../../components';
import DeliveryRadiusSelector from './DeliveryRadiusSelector';

import css from './EditListingDeliveryForm.module.css';

const { Money } = sdkTypes;

export const EditListingDeliveryFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={formRenderProps => {
      const {
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        deliveryRadiusOptions,
        values,
        form,
      } = formRenderProps;

      const { ownerDelivery, renterPickUp, deliveryFee, deliveryRadius, city, state, street, zip } = values;
      const hasDelivery = ownerDelivery.includes('ownerDelivery');
      const hasPickUp = renterPickUp.includes('renterPickUp');

      const invalidPickUpAddress = hasPickUp && !(city && state && street && zip);
      const invalidDeliveryData = hasDelivery && !(deliveryFee?.amount >= 0 && deliveryRadius);

      const { updateListingError, showListingsError } = fetchErrors || {};
      const errorMessage = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDeliveryForm.updateFailed" />
        </p>
      ) : null;
      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDeliveryForm.showListingFailed" />
        </p>
      ) : null;

      const labelDeliveryFee = intl.formatMessage({ id: 'EditListingDeliveryForm.labelDeliveryFee' });
      const labelAddressStreet = intl.formatMessage({ id: 'EditListingDeliveryForm.labelAddressStreet' });
      const labelAddressCity = intl.formatMessage({ id: 'EditListingDeliveryForm.labelAddressCity' });
      const labelAddressZip = intl.formatMessage({ id: 'EditListingDeliveryForm.labelAddressZip' });
      const placeholderDeliveryFeeMessage = intl.formatMessage({ id: 'EditListingDeliveryForm.placeholderDeliveryFeeMessage' });
      const placeholderPickupAddressStreet = intl.formatMessage({ id: 'EditListingDeliveryForm.placeholderPickupAddressStreet' });
      const placeholderPickupAddressCity = intl.formatMessage({ id: 'EditListingDeliveryForm.placeholderPickupAddressCity' });
      const placeholderPickupAddressZip = intl.formatMessage({ id: 'EditListingDeliveryForm.placeholderPickupAddressZip' });

      const deliveryFeeRequired = required(intl.formatMessage({ id: 'EditListingDeliveryForm.deliveryFeeRequired' }));
      const minDeliveryFee = new Money(config.listingMinimumDeliveryFeeSubUnits, config.currency);
      const maxDeliveryFee = new Money(config.listingMaximumDeliveryFeeSubUnits, config.currency);
      const minDeliveryFeeRequired = moneySubUnitAmountAtLeast(
        intl.formatMessage({ id: 'EditListingDeliveryForm.deliveryFeeTooLow' }, { minDeliveryFee: formatMoney(intl, minDeliveryFee) }),
        config.listingMinimumDeliveryFeeSubUnits);
      const maxDeliveryFeeRequired = moneySubUnitAmountNoMore(
        intl.formatMessage({ id: 'EditListingDeliveryForm.deliveryFeeTooHigh' }, { maxDeliveryFee: formatMoney(intl, maxDeliveryFee) }),
        config.listingMaximumDeliveryFeeSubUnits);
      const deliveryFeeValidators = config.listingMaximumDeliveryFeeSubUnits
        ? composeValidators(deliveryFeeRequired, maxDeliveryFeeRequired)
        : config.listingMinimumDeliveryFeeSubUnits
          ? composeValidators(deliveryFeeRequired, minDeliveryFeeRequired)
          : deliveryFeeRequired;
      const deliveryRadiusRequiredMessage = intl.formatMessage({ id: 'EditListingDeliveryForm.deliveryRadiusRequiredMessage' });

      const streetRequiredMessage = intl.formatMessage({ id: 'EditListingDeliveryForm.streetRequiredMessage' });
      const cityRequiredMessage = intl.formatMessage({ id: 'EditListingDeliveryForm.cityRequiredMessage' });
      const zipRequiredMessage = required(intl.formatMessage({ id: 'EditListingDeliveryForm.zipRequiredMessage' }));
      const minZipRequired = minLength(intl.formatMessage({ id: 'EditListingDeliveryForm.zipTooLow' }, { minZipLength: MIN_ZIP_LENGTH }), MIN_ZIP_LENGTH);
      const zipValidator = MIN_ZIP_LENGTH ? composeValidators(zipRequiredMessage, minZipRequired) : zipRequiredMessage;

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress || !(hasDelivery || hasPickUp) || invalidPickUpAddress || invalidDeliveryData;

      useEffect(() => {
        form.resetFieldState('deliveryFee');
        form.resetFieldState('deliveryRadius');
      }, [hasDelivery]);

      useEffect(() => {
        form.resetFieldState('street');
        form.resetFieldState('city');
        form.resetFieldState('state');
        form.resetFieldState('zip');
      }, [hasPickUp]);

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessage}
          {errorMessageShowListing}
          <div className={css.detailsWrapper}>
            <div className={css.delivery}>
              <div className={css.deliveryType}>
                <FieldCheckbox
                  id="ownerDelivery"
                  name="ownerDelivery"
                  label="Owner Delivery"
                  value="ownerDelivery"
                />
              </div>
              <FieldCurrencyInput
                id="deliveryFee"
                name="deliveryFee"
                className={classNames(css.deliveryFee, {
                  [css.disabled]: !hasDelivery,
                })}
                label={labelDeliveryFee}
                placeholder={placeholderDeliveryFeeMessage}
                currencyConfig={config.currencyConfig}
                disabled={!hasDelivery}
                validate={hasDelivery && deliveryFeeValidators}
              />
              <DeliveryRadiusSelector
                id="deliveryRadius"
                name="deliveryRadius"
                deliveryRadius={deliveryRadiusOptions}
                intl={intl}
                disabled={!hasDelivery}
                validate={hasDelivery && composeValidators(required(deliveryRadiusRequiredMessage))}
              />
            </div>
            <div className={css.delivery}>
              <div className={css.deliveryType}>
                <FieldCheckbox
                  className={css.deliveryType}
                  id="renterPickUp"
                  name="renterPickUp"
                  label="Renter PickUp"
                  value="renterPickUp"
                />
              </div>
              <FieldTextInput
                id="street"
                name="street"
                className={classNames(css.street, {
                  [css.disabled]: !hasPickUp,
                })}
                type="text"
                label={labelAddressStreet}
                placeholder={placeholderPickupAddressStreet}
                validate={hasPickUp && composeValidators(required(streetRequiredMessage))}
                disabled={!hasPickUp}
              />
              <FieldTextInput
                id="city"
                name="city"
                className={classNames(css.city, {
                  [css.disabled]: !hasPickUp,
                })}
                type="text"
                label={labelAddressCity}
                placeholder={placeholderPickupAddressCity}
                validate={hasPickUp && composeValidators(required(cityRequiredMessage))}
                disabled={!hasPickUp}
              />
              <div className={css.stateZipWrapper}>
                <FieldSelectState
                  className={classNames(css.state, {
                    [css.disabled]: !hasPickUp,
                  })}
                  id={"state"}
                  name={"state"}
                  disabled={!hasPickUp}
                />
                <FieldZipInput
                  id="zip"
                  name="zip"
                  type="text"
                  className={classNames(css.zip, {
                    [css.disabled]: !hasPickUp,
                  })}
                  label={labelAddressZip}
                  placeholder={placeholderPickupAddressZip}
                  validate={hasPickUp && zipValidator}
                  disabled={!hasPickUp}
                />
              </div>
            </div>
          </div>
          <EditListingButtonBar
            submitInProgress={submitInProgress}
            submitDisabled={submitDisabled}
            submitReady={submitReady}
            saveActionMsg={saveActionMsg}
          />
        </Form>
      );
    }}
  />
);

EditListingDeliveryFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  fetchErrors: null,
};

EditListingDeliveryFormComponent.propTypes = {
  intl: intlShape.isRequired,
  rootClassName: string,
  className: string,
  name: string.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  deliveryRadiusOptions: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ),
  // dropInitials: func,
};

export default compose(injectIntl)(EditListingDeliveryFormComponent);
