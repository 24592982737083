import React from 'react';
import { compose } from 'redux';
import { string, func } from 'prop-types';
import { Form as FinalForm } from 'react-final-form';
import { OnChange as FinalFormOnChange } from 'react-final-form-listeners';
import { injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { Form, FieldCheckbox } from '../../components';

import css from './ManageListingsFilterForm.module.css';

export const ManageListingsFilterFormComponent = props => (
  <FinalForm
    {...props}
    render={formRenderProps => {
      const {
        className,
        handleSubmit,
      } = formRenderProps;

      const closedOnlyLabel = <FormattedMessage id="ManageListingsFilterForm.closedOnlyLabel" />;
      const classes = classNames(css.root, className);

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <FieldCheckbox id={"showClosedOnly"} name={"showClosedOnly"} label={closedOnlyLabel} value={"closedOnly"} />
          <FinalFormOnChange name={"showClosedOnly"}>
            {(value, previous) => handleSubmit(value.length > 0)}
          </FinalFormOnChange>
        </Form>
      );
    }}
  />
);

ManageListingsFilterFormComponent.defaultProps = { className: null };

ManageListingsFilterFormComponent.propTypes = {
  className: string,
  onSubmit: func.isRequired,
};

export default compose(injectIntl)(ManageListingsFilterFormComponent);
