import { categories } from "./category-config";
import { quantities } from "./quantity-config";

/*
 * Marketplace specific configuration.
 *
 * Every filter needs to have following keys:
 * - id:     Unique id of the filter.
 * - label:  The default label of the filter.
 * - type:   String that represents one of the existing filter components:
 *           BookingDateRangeFilter, KeywordFilter, PriceFilter,
 *           SelectSingleFilter, SelectMultipleFilter.
 * - group:  Is this 'primary' or 'secondary' filter?
 *           Primary filters are visible on desktop layout by default.
 *           Secondary filters are behind "More filters" button.
 *           Read more from src/containers/SearchPage/README.md
 * - queryParamNames: Describes parameters to be used with queries
 *                    (e.g. 'price' or 'pub_amenities'). Most of these are
 *                    the same between webapp URLs and API query params.
 *                    You can't change 'dates', 'price', or 'keywords'
 *                    since those filters are fixed to a specific attribute.
 * - config: Extra configuration that the filter component needs.
 *
 * Note 1: Labels could be tied to translation file
 *         by importing FormattedMessage:
 *         <FormattedMessage id="some.translation.key.here" />
 *
 * Note 2: If you need to add new custom filter components,
 *         you need to take those into use in:
 *         src/containers/SearchPage/FilterComponent.js
 *
 * Note 3: If you just want to create more enum filters
 *         (i.e. SelectSingleFilter, SelectMultipleFilter),
 *         you can just add more configurations with those filter types
 *         and tie them with correct extended data key
 *         (i.e. pub_<key> or meta_<key>).
 */

export const filters = [
  {
    id: 'category',
    type: 'CategoryFilter',
    group: 'primary',
    queryParamNames: ['pub_category'],
    config: { options: categories }
  },
  {
    id: 'dates',
    label: 'Dates',
    type: 'BookingDateRangeFilter',
    group: 'primary',
    // Note: BookingDateRangeFilter is fixed filter,
    // you can't change "queryParamNames: ['dates'],"
    queryParamNames: ['dates'],
    config: {},
  },
  {
    id: 'quantity',
    label: 'Quantity',
    type: 'QuantityFilter',
    group: 'primary',
    queryParamNames: ['pub_quantityTag'],
    config: {
      options: quantities
    }
  },
  {
    id: 'delivery',
    label: 'Delivery',
    type: 'DeliveryFilter',
    group: 'primary',
    queryParamNames: ['pub_deliveryType'],
    config: {
      options: [
        { key: 'ownerDelivery', label: 'Vendor delivers' },
        { key: 'renterPickUp', label: 'Renter pick ups' },
      ]
    }
  },
  {
    id: 'state',
    label: 'State',
    type: 'SelectStateFilter',
    group: 'primary',
    queryParamNames: ['pub_state'],
    config: {
      options: [
        { key: 'AL', label: 'Alabama' },
        { key: 'AK', label: 'Alaska' },
        { key: 'AZ', label: 'Arizona' },
        { key: 'AR', label: 'Arkansas' },
        { key: 'CA', label: 'California' },
        { key: 'CO', label: 'Colorado' },
        { key: 'CT', label: 'Connecticut' },
        { key: 'DE', label: 'Delaware' },
        { key: 'DC', label: 'District of Columbia' },
        { key: 'FL', label: 'Florida' },
        { key: 'GA', label: 'Georgia' },
        { key: 'HI', label: 'Hawaii' },
        { key: 'ID', label: 'Idaho' },
        { key: 'IL', label: 'Illinois' },
        { key: 'IN', label: 'Indiana' },
        { key: 'IA', label: 'Iowa' },
        { key: 'KS', label: 'Kansas' },
        { key: 'KY', label: 'Kentucky' },
        { key: 'LA', label: 'Louisiana' },
        { key: 'ME', label: 'Maine' },
        { key: 'MD', label: 'Maryland' },
        { key: 'MA', label: 'Massachusetts' },
        { key: 'MI', label: 'Michigan' },
        { key: 'MN', label: 'Minnesota' },
        { key: 'MS', label: 'Mississippi' },
        { key: 'MO', label: 'Missouri' },
        { key: 'MT', label: 'Montana' },
        { key: 'NE', label: 'Nebraska' },
        { key: 'NV', label: 'Nevada' },
        { key: 'NH', label: 'New Hampshire' },
        { key: 'NJ', label: 'New Jersey' },
        { key: 'NM', label: 'New Mexico' },
        { key: 'NY', label: 'New York' },
        { key: 'NC', label: 'North Carolina' },
        { key: 'ND', label: 'North Dakota' },
        { key: 'OH', label: 'Ohio' },
        { key: 'OK', label: 'Oklahoma' },
        { key: 'OR', label: 'Oregon' },
        { key: 'PA', label: 'Pennsylvania' },
        { key: 'PR', label: 'Puerto Rico' },
        { key: 'RI', label: 'Rhode Island' },
        { key: 'SC', label: 'South Carolina' },
        { key: 'SD', label: 'South Dakota' },
        { key: 'TN', label: 'Tennessee' },
        { key: 'TX', label: 'Texas' },
        { key: 'UT', label: 'Utah' },
        { key: 'VT', label: 'Vermont' },
        { key: 'VA', label: 'Virginia' },
        { key: 'WA', label: 'Washington' },
        { key: 'WV', label: 'West Virginia' },
        { key: 'WI', label: 'Wisconsin' },
        { key: 'WY', label: 'Wyoming' },
      ],
    },
  }
];

export const sortConfig = {
  // Enable/disable the sorting control in the SearchPage
  active: true,

  // Note: queryParamName 'sort' is fixed,
  // you can't change it since Flex API expects it to be named as 'sort'
  queryParamName: 'sort',

  // Internal key for the relevance option, see notes below.
  relevanceKey: 'relevance',

  // Keyword filter is sorting the results already by relevance.
  // If keyword filter is active, we need to disable sorting.
  conflictingFilters: ['keyword'],

  options: [
    { key: 'createdAt', label: 'Newest' },
    { key: '-createdAt', label: 'Oldest' },
    { key: '-price', label: 'Price: Low to High' },
    { key: 'price', label: 'Price: High to Low' },
  ],
};
