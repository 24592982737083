import React from 'react';
import { bool, string } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import config from '../../config';
import { eventsBlocks, eventsGrid } from '../../events-config.js';
import { menuCategories as categories } from '../../category-home-config.js';
import { ExternalLink, NamedLink, Icon } from '../../components';
import IconLogo from '../../assets/iconLogo.png';

import css from './Footer.module.css';

const renderSocialMediaLinks = () => {
  const { socialMedia } = config;
  const polygonLink = socialMedia => socialMedia.url &&
    <ExternalLink key={socialMedia.key} href={socialMedia.url}>
      <div className={css.polygonLink}>
        <Icon className={css.polygon} iconID="polygon" />
        <Icon className={css.mediaIcon} iconID={socialMedia.key} />
      </div>
    </ExternalLink>
  return (
    <div className={css.socialMedia}>
      {socialMedia.map(polygonLink)}
    </div>
  )
};

const Footer = props => {
  const { rootClassName, className, isAwaitCallToActions } = props;
  const { locationByDefault, CMSPageId } = config;
  const { address, bounds } = locationByDefault;
  const searchPath = `address=${address}&bounds=${bounds}`;

  const events = [...eventsBlocks, ...eventsGrid];
  const getEventLink = e => e && `event-${e}`;

  const rentalsByEvents = events.map(item =>
    <NamedLink key={item.key} className={css.link} name="CMSPage" params={{ pageId: getEventLink(item.key) }}>
      {item.label}
    </NamedLink>);

  const rentalsCategories = categories.map(item =>
    <NamedLink key={item.key} className={css.link} name="SearchPage" to={{ search: searchPath + `&pub_category=${item.key}` }}>
      {item.label}
    </NamedLink>);

  const socialMediaLinks = renderSocialMediaLinks();
  const rootClasses = isAwaitCallToActions ? css.callToActionsRoot : css.root;
  const classes = classNames(rootClassName || rootClasses, className);
  const classesWrapper = isAwaitCallToActions ? css.topBorderForCallToActions : css.topBorderWrapper;

  return (
    <div className={classes}>
      <div className={classesWrapper}>
        <div className={css.content}>
          <div className={css.links}>
            <div className={css.moochRentersVendors}>
              <div className={css.listWrapper}>
                <span className={css.header}><FormattedMessage id="Footer.mooch" /></span>
                <NamedLink className={css.link} name="CMSPage" params={{ pageId: CMSPageId.about }}>
                  <FormattedMessage id="Footer.toAboutPage" />
                </NamedLink>
                <NamedLink className={css.link} name="CMSPage" params={{ pageId: CMSPageId.faq }}>
                  <FormattedMessage id="Footer.toFaqPage" />
                </NamedLink>
                <a className={css.link} href='mailto:hello@eventexchange.co' >
                  <FormattedMessage id="Footer.toContactPage" />
                </a>
              </div>
            </div>
            <div className={css.forRenters}>
              <div className={css.listWrapper}>
                <span className={css.header}><FormattedMessage id="Footer.renters" /></span>
                <NamedLink className={css.linkWithIcon} name="SearchPage" to={{ search: searchPath }} >
                  <Icon className={css.iconLink} iconID="rentalsSmallButton" />
                  <FormattedMessage id="Footer.browseRentals" />
                </NamedLink>
                <NamedLink className={css.link} name="CMSPage" params={{ pageId: CMSPageId.forRenters }} >
                  <FormattedMessage id="Footer.moochForRenters" />
                </NamedLink>
                <NamedLink className={css.link} name="TermsOfServicePage" >
                  <FormattedMessage id="Footer.termsOfUse" />
                </NamedLink>
              </div>
            </div>
            <div className={css.forVendors}>
              <div className={css.listWrapper}>
                <span className={css.header}><FormattedMessage id="Footer.vendors" /></span>
                <NamedLink className={css.linkWithIcon} name="NewListingPage">
                  <Icon className={css.iconLink} iconID="add" />
                  <FormattedMessage id="Footer.addItem" />
                </NamedLink>
                <NamedLink className={css.link} name="CMSPage" params={{ pageId: CMSPageId.forVendors }} >
                  <FormattedMessage id="Footer.moochForVendors" />
                </NamedLink>
                <NamedLink className={css.link} name="PrivacyPolicyPage" >
                  <FormattedMessage id="Footer.privacyPolicy" />
                </NamedLink>
              </div>
            </div>
            <div className={css.moochInfo}>
              <div className={css.logoWrapper}>
                <img src={IconLogo} className={css.iconLogo} />
                <span className={css.description}><FormattedMessage id="Footer.moochDescription" /></span>
              </div>
              {socialMediaLinks}
            </div>
          </div>
          <div className={css.eventsAndCategories}>
            <div className={css.eventsWrapper}>
              <span className={css.header}><FormattedMessage id="Footer.rentalsByEvents" /></span>
              <div className={css.events}>
                {rentalsByEvents}
              </div>
            </div>
            <div className={css.categoriesWrapper}>
              <span className={css.header}><FormattedMessage id="Footer.rentalsCategories" /></span>
              <div className={css.categories}>
                {rentalsCategories}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Footer.defaultProps = {
  rootClassName: null,
  className: null,
  isAwaitCallToActions: false
};

Footer.propTypes = {
  rootClassName: string,
  className: string,
  intl: intlShape.isRequired,
  isAwaitCallToActions: bool
};

export default injectIntl(Footer);
