import React from 'react';
import { bool, object, string } from 'prop-types';
import classNames from 'classnames';
import config from '../../config';
import { FormattedMessage } from '../../util/reactIntl';
import { Icon, NamedLink } from '../../components';

import css from './RentalCategoryItem.module.css';

const RentalCategoryItem = props => {
  const { className, iconClassName, category, isOdd } = props;
  const { key, label } = category || {};
  const { address, bounds } = config.locationByDefault;
  const classCategoryItem = isOdd ? css.oddCategoryItem : css.evenCategoryItem;
  const classes = classNames(classCategoryItem, className);

  return (
    key === 'rentals' ?
      <NamedLink
        className={classNames(css.allCategoriesLink, className)}
        name="SearchPage"
        to={{ search: `address=${address}&bounds=${bounds}` }}
      >
        <Icon className={css.categoryIcon} iconID="rentals" />
        <FormattedMessage id="RentalCategoryItem.allRentals" />
      </NamedLink>
      :
      <NamedLink
        className={classes}
        name="SearchPage"
        to={{ search: `address=${address}&bounds=${bounds}&pub_category=${key}` }}
      >
        <Icon className={classNames(css.categoryIcon, iconClassName)} iconID={key} source='categories' />
        {label}
      </NamedLink>
  );
}

RentalCategoryItem.defaultProps = {
  className: null,
  iconClassName: null,
  category: null,
  isOdd: false
};

RentalCategoryItem.propTypes = {
  className: string,
  iconClassName: string,
  category: object,
  isOdd: bool
}

export default RentalCategoryItem;
