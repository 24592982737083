export const getChildlessCategories = (list) => {
    const result = [];
    for (let i = 0; i < list.length; i++) {
        const categoryItem = list[i];
        if (categoryItem.items) {
            result.push(...getChildlessCategories(categoryItem.items));
        } else {
            result.push(categoryItem);
        }
    }
    const sortedResult = result.sort((item1, item2) => item1.label < item2.label ? -1 : 1);
    return sortedResult;
}