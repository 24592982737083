import React from 'react';
import classNames from 'classnames';
import { number, string, arrayOf, object, func } from 'prop-types';

import css from './KeywordTopbarSearch.module.css';

const getTouchCoordinates = nativeEvent => {
  const touch = nativeEvent && nativeEvent.changedTouches ? nativeEvent.changedTouches[0] : null;
  return touch ? { x: touch.screenX, y: touch.screenY } : null;
};

const CategoryPredictionsList = props => {
  const {
    rootClassName,
    className,
    predictions,
    highlightedIndex,
    onSelectCategory,
    onSelectStart,
    onSelectMove,
    onSelectEnd,
  } = props;

  if (predictions.length === 0) {
    return null;
  }

  const item = (prediction) => {
    const { key, label, items } = prediction;
    const listForSearch = items ? items.map((item) => item.key).join(',') : key;
    const isHighlighted = key === highlightedIndex;
    return (
      <li
        className={isHighlighted ? css.highlighted : null}
        key={key}
        onClick={e => {
          e.preventDefault();
          onSelectCategory(listForSearch);
        }}
        onTouchStart={e => {
          e.preventDefault();
          onSelectStart(getTouchCoordinates(e.nativeEvent));
        }}
        onMouseDown={e => {
          e.preventDefault();
          onSelectStart();
        }}
        onTouchMove={e => {
          e.preventDefault();
          onSelectMove(getTouchCoordinates(e.nativeEvent));
        }}
        onTouchEnd={e => {
          e.preventDefault();
          onSelectEnd(listForSearch);
        }}
        onMouseUp={e => {
          e.preventDefault();
          onSelectEnd(listForSearch);
        }}
      >
        {label}
      </li>
    );
  };

  const classes = classNames(rootClassName || css.predictionsRoot, className);

  return (
    <div className={classes}>
      <ul className={css.predictions}>{predictions.map(item)}</ul>
    </div>
  );
};

CategoryPredictionsList.defaultProps = {
  rootClassName: null,
  className: null,
  highlightedIndex: null,
};

CategoryPredictionsList.propTypes = {
  rootClassName: string,
  className: string,
  predictions: arrayOf(object).isRequired,
  highlightedIndex: number,
  onSelectCategory: func.isRequired,
  onSelectStart: func.isRequired,
  onSelectMove: func.isRequired,
  onSelectEnd: func.isRequired,
};

export default CategoryPredictionsList;
