import React from "react";
import { bool, func, string } from "prop-types";

import css from './FieldToggleButton.module.css';

const FieldToggleButton = props => {
  const { label, isToggled, onToggle } = props;
  return (
    <div className={css.toggleWrapper}>
      <span className={css.toggleLabel}>{label}</span>
      <label className={css.toggleSwitch}>
        <input type="checkbox" checked={isToggled} onChange={onToggle} />
        <span className={css.switch} />
      </label>
    </div>
  );
};

FieldToggleButton.propTypes = {
  label: string.isRequired,
  isToggled: bool.isRequired,
  onToggle: func.isRequired
};


export default FieldToggleButton;
