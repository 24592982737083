import { types as sdkTypes } from './util/sdkLoader';

const { LatLng, LatLngBounds } = sdkTypes;

// An array of locations to show in the LocationAutocompleteInput when
// the input is in focus but the user hasn't typed in any search yet.
//
// Each item in the array should be an object with a unique `id` (String) and a
// `predictionPlace` (util.types.place) properties.
const defaultLocations = [
  {
    id: 'default-santa-monica',
    predictionPlace: {
      address: 'Santa Monica, California',
      bounds: new LatLngBounds(new LatLng(34.1059562, -118.39928018), new LatLng(33.92339167, -118.57622747)),
    },
  },
  {
    id: 'default-long-beach',
    predictionPlace: {
      address: 'Long Beach, California',
      bounds: new LatLngBounds(new LatLng(34.00962711, -117.956505), new LatLng(33.56825735, -118.383165)),
    },
  },
  {
    id: 'default-laguna-beach',
    predictionPlace: {
      address: 'Laguna Beach, California',
      bounds: new LatLngBounds(new LatLng(33.58855909, -117.74525042), new LatLng(33.50471249, -117.82607426)),
    },
  },
  {
    id: 'default-santa-barbara',
    predictionPlace: {
      address: 'Santa Barbara, California',
      bounds: new LatLngBounds(new LatLng(34.58391328, -119.56598791), new LatLng(34.29389875, -119.84849891)),
    },
  },
];
export default defaultLocations;
