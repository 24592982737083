import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import config from '../../config';
import { ACCOUNT_SETTINGS_PAGES } from '../../routeConfiguration';
import { propTypes } from '../../util/types';
import {
  Avatar,
  InlineTextButton,
  Logo,
  Menu,
  MenuLabel,
  MenuContent,
  MenuItem,
  NamedLink,
  Icon
} from '../../components';
import { TopbarSearchKeywordsForm } from '../../forms';

import css from './TopbarDesktop.module.css';

const TopbarDesktop = props => {
  const {
    className,
    currentUser,
    currentPage,
    rootClassName,
    currentUserHasListings,
    notificationCount,
    intl,
    isAuthenticated,
    onLogout,
    onSearchSubmitKeywords,
    onSearchSubmitCategory,
    initialSearchFormValues,
  } = props;
  const [mounted, setMounted] = useState(false);
  const [isOpenMenu, setIsOpenMenu] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  const authenticatedOnClientSide = mounted && isAuthenticated;
  const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted;
  const notificationDot = notificationCount > 0 ? <div className={css.notificationDot} /> : null;
  const { locationByDefault, CMSPageId } = config;
  const { address, bounds } = locationByDefault;
  const searchPath = `address=${address}&bounds=${bounds}`;

  const classes = classNames(rootClassName || css.root, className);

  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  const onToggleActive = isOpenMenu => setIsOpenMenu(isOpenMenu);
  const selectOption = () => setIsOpenMenu(false);

  const logoLink = <NamedLink className={css.logoLink} name="LandingPage">
    <Logo
      format="desktop"
      className={css.logo}
      alt={intl.formatMessage({ id: 'TopbarDesktop.logo' })}
    />
  </NamedLink>

  const search =
    <TopbarSearchKeywordsForm
      className={css.searchLink}
      desktopInputRoot={css.topbarSearchWithLeftPadding}
      onSubmit={onSearchSubmitKeywords}
      onSelectCategory={onSearchSubmitCategory}
      initialValues={initialSearchFormValues}
    />;

  const allRentalsButton =
    <NamedLink name="SearchPage" className={css.allRentalsButton} to={{ search: searchPath }}>
      <Icon className={css.addIcon} iconID="rentalsSmallButton" />
      <FormattedMessage id="TopbarDesktop.allRentals" />
    </NamedLink>

  const howMoochWorks =
    <Menu
      onToggleActive={onToggleActive}
      isOpen={isOpenMenu}
    >
      <MenuLabel className={css.createListingLink} isOpenClassName={css.profileMenuIsOpen}>
        <span className={css.createListing}>
          <FormattedMessage id="TopbarDesktop.howMoochWorks" />
          <Icon className={css.arrowIcon} iconID="arrowDown" />
        </span>
      </MenuLabel>
      <MenuContent isArrow={false} className={css.howMoochWorksMenuContent}>
        <MenuItem key="ProfileSettingsPage" onSelectItem={selectOption}>
          <NamedLink
            className={classNames(css.profileSettingsLink, currentPageClass('ProfileSettingsPage'))}
            name="CMSPage"
            params={{ pageId: CMSPageId.forRenters }}
          >
            <FormattedMessage id="TopbarDesktop.wantToRent" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="AccountSettingsPage" onSelectItem={selectOption}>
          <NamedLink
            className={classNames(css.accountSettingsLink, currentPageClass('AccountSettingsPage'))}
            name="CMSPage"
            params={{ pageId: CMSPageId.forVendors }}
          >
            <FormattedMessage id="TopbarDesktop.wantToVendor" />
          </NamedLink>
        </MenuItem>
      </MenuContent>
    </Menu>

  const yourListings = authenticatedOnClientSide ? (<NamedLink
    className={css.yourListingsLink}
    name="ManageListingsPage"
  >
    <FormattedMessage id="TopbarDesktop.yourListingsLink" />
  </NamedLink>) : null;

  const inboxLink = authenticatedOnClientSide ? (
    <NamedLink
      className={css.inboxLink}
      name="InboxPage"
      params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
    >
      <span className={css.inbox}>
        <Icon className={css.inboxIcon} iconID="inbox" />
        {notificationDot}
      </span>
    </NamedLink>) : null;

  const profileMenu = authenticatedOnClientSide ? (
    <Menu>
      <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
        <Avatar className={css.avatar} user={currentUser} disableProfileLink />
      </MenuLabel>
      <MenuContent isArrow={false} className={css.profileMenuContent}>
        <MenuItem key="ProfileSettingsPage">
          <NamedLink
            className={classNames(css.profileSettingsLink, currentPageClass('ProfileSettingsPage'))}
            name="ProfileSettingsPage"
          >
            <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="AccountSettingsPage">
          <NamedLink
            className={classNames(css.accountSettingsLink, currentPageClass('AccountSettingsPage'))}
            name="AccountSettingsPage"
          >
            <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="logout">
          <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
            <FormattedMessage id="TopbarDesktop.logout" />
          </InlineTextButton>
        </MenuItem>
      </MenuContent>
    </Menu>
  ) : null;

  const createListing = <NamedLink className={css.createListingLink} name="NewListingPage">
    <span className={css.createListing}>
      <Icon className={css.addIcon} iconID="add" />
      <FormattedMessage id="TopbarDesktop.createListing" />
    </span>
  </NamedLink>

  const signupLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="SignupPage" className={css.signupLink}>
      <span className={css.signup}>
        <FormattedMessage id="TopbarDesktop.signup" />
      </span>
    </NamedLink>
  );

  const loginLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="LoginPage" className={css.loginLink}>
      <span className={css.login}>
        <FormattedMessage id="TopbarDesktop.login" />
      </span>
    </NamedLink>
  );

  return (
    <nav className={classes}>
      <div className={css.logoSearchWrapper}>
        {logoLink}
        {search}
        {allRentalsButton}
      </div>
      <div className={css.rightButtonsWrapper}>
        {howMoochWorks}
        {yourListings}
        {createListing}
        {inboxLink}
        {profileMenu}
        {loginLink}
        {signupLink}
      </div>
    </nav >
  );
};

const { bool, func, object, number, string } = PropTypes;

TopbarDesktop.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  currentPage: null,
  notificationCount: 0,
  initialSearchFormValues: {},
  searchByLocation: false,
};

TopbarDesktop.propTypes = {
  rootClassName: string,
  className: string,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  isAuthenticated: bool.isRequired,
  onLogout: func.isRequired,
  notificationCount: number,
  onSearchSubmitKeywords: func.isRequired,
  onSearchSubmitCategory: func.isRequired,
  initialSearchFormValues: object,
  intl: intlShape.isRequired,
  searchByLocation: bool.isRequired,
};

export default TopbarDesktop;
