import React from 'react';
import PropTypes, { bool, func } from 'prop-types';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { ListingCard, PaginationLinks, Icon } from '../../components';
import css from './SearchResultsPanel.module.css';
import { FormattedMessage } from 'react-intl';

const SearchResultsPanel = props => {
  const {
    className,
    rootClassName,
    listings,
    pagination,
    search,
    setActiveListing,
    isShowMap,
    currentGeoPosition,
    onMapIconClick,
    mobileSortingComponent
  } = props;
  const classes = classNames(rootClassName || css.root, className);

  const paginationLinks =
    pagination && pagination.totalPages > 1 ? (
      <PaginationLinks
        className={css.pagination}
        pageName="SearchPage"
        pageSearchParams={search}
        pagination={pagination}
      />
    ) : null;

  // Panel width relative to the viewport
  const panelMediumWidth = 50;
  const panelLargeWidth = 62.5;
  const cardRenderSizes = [
    '(max-width: 767px) 100vw',
    `(max-width: 1023px) ${panelMediumWidth}vw`,
    `(max-width: 1920px) ${panelLargeWidth / 2}vw`,
    `${panelLargeWidth / 3}vw`,
  ].join(', ');

  const listingCardsClass = isShowMap ? css.listingCards : css.listingCardsWithoutMap;
  const noResultCssClass = classNames(css.noSearchResults, { [css.noSearchResultsWithoutMap]: !isShowMap });

  return (
    <div className={classes}>
      {listings?.length > 0 && (
        <div className={listingCardsClass}>
          <div className={css.mobileSorting}>
            <span className={css.foundResults}>
                <FormattedMessage
                  id="SearchFiltersPrimary.foundResults"
                  values={{ count: pagination?.totalItems }}
                />
            </span>
            {mobileSortingComponent}
          </div>
          {listings.map(l => (
            <ListingCard
              className={css.listingCard}
              key={l.id.uuid}
              listing={l}
              renderSizes={cardRenderSizes}
              setActiveListing={setActiveListing}
              currentGeoPosition={currentGeoPosition}
            />
          ))}
          {props.children}
        </div>
      )}
      {paginationLinks}
      {pagination?.totalItems === 0 && (
        <div className={noResultCssClass}>
          <div className={css.noSearchResultsIcon}>
            <Icon className={css.searchIcon} iconID='loupe' />
            <Icon className={css.closeIcon} iconID='close' />
          </div>
          <FormattedMessage id="SearchFiltersPrimary.noResults" />
        </div>
      )}
      {
        pagination?.totalItems > 0 && (
          <div className={css.mapIcon} onClick={onMapIconClick}>
            <Icon className={css.icon} iconID='map' />
            <FormattedMessage id="SearchResultsPanel.openMapView" className={css.mapIconText} />
          </div>
        )
      }
    </div>
  );
};

SearchResultsPanel.defaultProps = {
  children: null,
  className: null,
  listings: [],
  pagination: null,
  rootClassName: null,
  search: null,
  isShowMap: true,
  currentGeoPosition: null,
  mobileSortingComponent: null,
};

const { array, node, object, string } = PropTypes;

SearchResultsPanel.propTypes = {
  children: node,
  className: string,
  listings: array,
  pagination: propTypes.pagination,
  rootClassName: string,
  search: object,
  isShowMap: bool.isRequired,
  currentGeoPosition: object,
  onMapIconClick: func.isRequired,
  mobileSortingComponent: node
};

export default SearchResultsPanel;
