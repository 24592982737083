import React from 'react';
import { compose } from 'redux';
import { bool, func, node, number, string } from 'prop-types';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import { FieldToggleButton, Icon } from '../../components';
import classNames from 'classnames';

import css from './SearchFiltersPrimary.module.css';

const SearchFiltersPrimaryComponent = props => {
  const {
    intl,
    rootClassName,
    className,
    children,
    sortByComponent,
    listingsAreLoaded,
    resultsCount,
    searchInProgress,
    isShowMap,
    onToggleMap,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const toggleMapLabel = intl.formatMessage({ id: 'SearchFiltersPrimary.toggleMapLabel' });

  return (
    <>
      <div className={classes}>
        <div className={css.filters}>
          {children}
        </div>
        <div className={css.searchOptions}>
          {listingsAreLoaded ? (
            <div className={css.searchResultSummary}>
              <span className={css.resultsFound}>
                <FormattedMessage
                  id="SearchFiltersPrimary.foundResults"
                  values={{ count: resultsCount }}
                />
              </span>
            </div>
          ) : null}
          {sortByComponent}
          <div className={css.toggleMap}>
            <FieldToggleButton label={toggleMapLabel} isToggled={isShowMap} onToggle={onToggleMap} />
          </div>
        </div>

        {searchInProgress ? (
          <div className={css.loadingResults}>
            <FormattedMessage id="SearchFiltersPrimary.loadingResults" />
          </div>
        ) : null}
      </div>
    </>
  );
};

SearchFiltersPrimaryComponent.defaultProps = {
  rootClassName: null,
  className: null,
  resultsCount: null,
  searchInProgress: false,
  sortByComponent: null,
};

SearchFiltersPrimaryComponent.propTypes = {
  intl: intlShape.isRequired,
  rootClassName: string,
  className: string,
  listingsAreLoaded: bool.isRequired,
  resultsCount: number,
  searchInProgress: bool,
  sortByComponent: node,
  isShowMap: bool.isRequired,
  onToggleMap: func.isRequired,
};

const SearchFiltersPrimary = SearchFiltersPrimaryComponent;
export default compose(injectIntl)(SearchFiltersPrimary);
