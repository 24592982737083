import React from 'react';
import { useSelector } from 'react-redux';
import { object, string } from 'prop-types';
import classNames from 'classnames';
import { Icon, ResponsiveImage, NamedLink } from '../../components';
import { FormattedMessage } from '../../util/reactIntl';
import IconLogo from '../../assets/yellowLogo.png';

import css from './SectionCallToActions.module.css';

const SectionCallToActions = props => {
  const { className, details } = props;
  const isAuthenticated = useSelector((state) => state.Auth.isAuthenticated);
  const { appearance, title, description, blocks } = details || {};
  const hasBlocks = blocks?.length > 0;
  const { backgroundImage } = appearance || {};

  const getVariantNames = img => {
    const { variants } = img?.attributes || {};
    return variants ? Object.keys(variants) : [];
  };

  const renderBackground = <ResponsiveImage
    className={css.image}
    alt={''}
    image={backgroundImage}
    variants={getVariantNames(backgroundImage)}
  />

  const classes = classNames(className, css.sectionWrapper);

  return (
    <div className={classes}>
      <div className={css.sectionContent}>
        <div className={css.imageWrapper}>{renderBackground}</div>
        <div className={css.contentWrapper}>
          {/* <Icon className={css.logo} iconID="letterLogo" /> */}
          <img src={IconLogo} className={css.iconLogo} />
          <div className={css.title}>{title?.content}</div>
          <div className={css.description}>{description?.content}</div>
          {isAuthenticated && hasBlocks ? (
            <div className={css.buttonsWrapper}>
              {blocks.map((item, index) =>
                <a
                  key={index}
                  className={css.button}
                  href={item.callToAction.href}
                >
                  {item.callToAction.content}
                </a>
              )}
            </div>
          ) : (
            <NamedLink className={css.signUpButtonsWrapper} name="SignupPage">
              <FormattedMessage id="CallToActions.signUp" />
            </NamedLink>
          )}
        </div>
      </div>
    </div>
  );
}

SectionCallToActions.defaultProps = {
  className: null,
  details: null
};

SectionCallToActions.propTypes = {
  className: string,
  details: object
}

export default SectionCallToActions;
