import React from 'react';
import { array, object, string } from 'prop-types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { types as sdkTypes } from '../../util/sdkLoader';
import { formatMoney } from '../../util/currency';

import css from './BookingPanel.module.css';

const { Money } = sdkTypes;

const BookingPanelDeliveryMaybe = props => {
  const { intl, currentDeliveryType, deliveryType, delivery = {} } = props;
  const { deliveryFee, pickupAddress, deliveryRadius } = delivery;
  const formattedDeliveryFee = deliveryFee && Object.keys(deliveryFee).length ? formatMoney(intl, new Money(deliveryFee.amount, deliveryFee.currency)) : null;
  const isOwnerDelivery = currentDeliveryType === 'ownerDelivery';
  const isRenterPickUp = currentDeliveryType === 'renterPickUp';

  const renderOwnerDeliveryOptions = (
    isOwnerDelivery && formattedDeliveryFee && (
      <>
        <div className={css.deliveryFee}><FormattedMessage id="BookingPanel.deliveryFee" values={{ formattedDeliveryFee }} /></div>
        {deliveryRadius && (
          <div className={css.deliveryFee}><FormattedMessage id="BookingPanel.deliveryRadius" values={{ deliveryRadius }} /></div>
        )}
      </>
    ));

  const renderPickUpDeliveryOptions = (
    isRenterPickUp && pickupAddress.city && pickupAddress.zip && (
      <div className={css.pickupAddress}>
        <FormattedMessage id="BookingPanel.pickupAddress"
          values={{ city: pickupAddress.city, state: pickupAddress.state, zip: pickupAddress.zip }} />
      </div>
    ));

  return deliveryType?.length > 0 && (
    <div>
      {deliveryType?.length === 1 && currentDeliveryType && (
        <div className={css.deliveryLabel}>
          <FormattedMessage
            id={isOwnerDelivery
              ? "BookingPanel.ownerDelivery"
              : "BookingPanel.renterPickUp"} />
        </div>
      )}
      {renderOwnerDeliveryOptions}
      {renderPickUpDeliveryOptions}
    </div>
  )
};

BookingPanelDeliveryMaybe.propTypes = {
  intl: intlShape.isRequired,
  currentDeliveryType: string,
  delivery: object,
  deliveryType: array,
};

export default BookingPanelDeliveryMaybe;
