import React from 'react';
import { object } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import { NamedLink, Icon } from '../../components';
import config from '../../config';
import IconLogo from '../../assets/iconLogo.png';

import css from './MoochHelps.module.css';

const MoochHelps = props => {
  const { details } = props;
  const { title, description } = details;
  const { CMSPageId } = config;

  const vendorsButtons = <>
    <NamedLink className={css.button} name="NewListingPage">
      <FormattedMessage id="MoochHelps.addItem" />
    </NamedLink>
    <NamedLink className={css.button} name="CMSPage" params={{ pageId: CMSPageId.forVendors }}>
      <FormattedMessage id="MoochHelps.howItWorks" />
    </NamedLink>
  </>

  const rentersButtons = <>
    <NamedLink className={css.button} name="ListingBasePage">
      <FormattedMessage id="MoochHelps.browseRentals" />
    </NamedLink>
    <NamedLink className={css.button} name="CMSPage" params={{ pageId: CMSPageId.forRenters }}>
      <FormattedMessage id="MoochHelps.howItWorks" />
    </NamedLink>
  </>

  return (
    <div className={css.moochHelpsWrapper}>
      <div className={css.moochHelpsTitle}>{title.content}</div>
      <div className={css.moochHelpsDescription}>{description.content}</div>
      <div className={css.moochHelpsContent}>

        <div className={css.forVendorsWrapped}>
          <div className={css.forVendors}>
            <FormattedMessage id="MoochHelps.vendorsTitle" />
          </div>
          <div className={css.itemVendorsOne}>
            <Icon className={css.icon} iconID='growth' source='benefits' />
            <FormattedMessage id="MoochHelps.vendorsItemOne" />
          </div>
          <div className={css.itemVendorsTwo}>
            <Icon className={css.icon} iconID='molecule' source='benefits' />
            <FormattedMessage id="MoochHelps.vendorsItemTwo" />
          </div>
          <div className={css.itemVendorsThree}>
            <Icon className={css.icon} iconID='help' source='benefits' />
            <FormattedMessage id="MoochHelps.vendorsItemThree" />
          </div>
          <div className={css.vendorsButtons}>{vendorsButtons}</div>
        </div>

        <div className={css.logoWrapper}><img src={IconLogo} className={css.iconLogo} /></div>

        <div className={css.forRentersWrapper}>
          <div className={css.forRenters}>
            <FormattedMessage id="MoochHelps.rentersTitle" />
          </div>
          <div className={css.itemRentersOne}>
            <Icon className={css.icon} iconID='eggChair' source='benefits' />
            <FormattedMessage id="MoochHelps.rentersItemOne" />
          </div>
          <div className={css.itemRentersTwo}>
            <Icon className={css.icon} iconID='map' source='benefits' />
            <FormattedMessage id="MoochHelps.rentersItemTwo" />
          </div>
          <div className={css.itemRentersThree}>
            <Icon className={css.icon} iconID='piggyBank' source='benefits' />
            <FormattedMessage id="MoochHelps.rentersItemThree" />
          </div>
          <div className={css.rentersButtons}>{rentersButtons}</div>
        </div>

      </div>
    </div>
  );
}

MoochHelps.defaultProps = {
  details: null
};

MoochHelps.propTypes = {
  details: object
}

export default MoochHelps;
