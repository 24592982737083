import React from 'react';
import { FieldSelect } from '../../components';
import classNames from 'classnames';

import css from './EditListingDeliveryForm.module.css';

const DeliveryRadiusSelector = props => {
  const { name, id, deliveryRadius, intl, disabled = false } = props;

  const labelDeliveryRadiusLabel = intl.formatMessage({ id: 'EditListingDeliveryForm.labelDeliveryRadiusLabel' });
  const placeholderDeliveryRadius = intl.formatMessage({ id: 'EditListingDeliveryForm.placeholderDeliveryRadius' });

  return deliveryRadius ? (
    <FieldSelect
      className={classNames(css.deliveryRadius, {
        [css.disabled]: disabled,
      })}
      name={name}
      id={id}
      label={labelDeliveryRadiusLabel}
      disabled={disabled}
    >
      <option disabled value="">
        {placeholderDeliveryRadius}
      </option>
      {deliveryRadius.map(c => (
        <option key={c.key} value={c.key}>
          {c.label}
        </option>
      ))}
    </FieldSelect>
  ) : null;
};

export default DeliveryRadiusSelector;
