import React, { Component } from 'react';
import { string, func, arrayOf, shape, number } from 'prop-types';
import classNames from 'classnames';

import { Menu, MenuContent, MenuItem, MenuLabel, Icon } from '../../components';
import css from './SortByPopup.module.css';
import { FormattedMessage, injectIntl } from 'react-intl';

const optionLabel = (options, key) => {
  const option = options.find(o => o.key === key);
  return option ? option.label : key;
};

class SortByPopup extends Component {
  constructor(props) {
    super(props);

    this.state = { isOpen: false };
    this.onToggleActive = this.onToggleActive.bind(this);
    this.selectOption = this.selectOption.bind(this);
  }

  onToggleActive(isOpen) {
    this.setState({ isOpen: isOpen });
  }

  selectOption(urlParam, option) {
    this.setState({ isOpen: false });
    this.props.onSelect(urlParam, option);
  }

  render() {
    const {
      urlParam,
      label,
      options,
      initialValue,
    } = this.props;

    const menuLabel = initialValue ? optionLabel(options, initialValue) : label;

    return (
      <Menu
        onToggleActive={this.onToggleActive}
        isOpen={this.state.isOpen}
      >
        <MenuLabel className={css.menuLabel}>
          <FormattedMessage id="SortBy.heading" /> <span className={css.menuLabelValue}>{menuLabel}</span>
          <Icon className={css.arrowIcon} iconID="arrowDown" />
        </MenuLabel>
        <MenuContent className={css.menuContent} isArrow={false}>
          {options.map(option => {
            // check if this option is selected
            const selected = initialValue === option.key;
            // menu item border class
            const menuItemClass = selected ? classNames(css.menuItem, css.menuItemSelected) : css.menuItem;

            return (
              <MenuItem key={option.key}>
                <button
                  className={menuItemClass}
                  disabled={option.disabled}
                  onClick={() => (selected ? null : this.selectOption(urlParam, option.key))}
                >
                  {option.longLabel || option.label}
                </button>
              </MenuItem>
            );
          })}
        </MenuContent>
      </Menu>
    );
  }
}

SortByPopup.defaultProps = {
  initialValue: null,
};

SortByPopup.propTypes = {
  urlParam: string.isRequired,
  label: string.isRequired,
  onSelect: func.isRequired,
  options: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ).isRequired,
  initialValue: string,
};

export default injectIntl(SortByPopup);
