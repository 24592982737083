/**
 *  TopbarMobileMenu prints the menu content for authenticated user or
 * shows login actions for those who are not authenticated.
 */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { InlineTextButton, NamedLink, Icon } from '../../components';
import config from '../../config';

import css from './TopbarMobileMenu.module.css';

const TopbarMobileMenu = props => {
  const { isAuthenticated, onLogout } = props;
  const { CMSPageId } = config;

  const [isShowAdditionalLinks, setIsShowAdditionalLinks] = useState(false);
  const rootClasses = isAuthenticated
    ? isShowAdditionalLinks
      ? css.authenticatedShowAdditional
      : css.authenticated
    : css.notAuthenticated;

  return (
    <div className={rootClasses}>
      <div className={css.content}>
        {!isAuthenticated ? (
          <>
            <NamedLink name="SignupPage" className={css.link}>
              <FormattedMessage id="TopbarMobileMenu.signupLink" />
            </NamedLink>
            <NamedLink name="LoginPage" className={css.link}>
              <FormattedMessage id="TopbarMobileMenu.loginLink" />
            </NamedLink>
          </>
        ) : (
          <NamedLink className={css.link} name="ManageListingsPage">
            <FormattedMessage id="TopbarMobileMenu.yourListingsLink" />
          </NamedLink>
        )}

        <NamedLink name="NewListingPage" className={css.addLink}>
          <Icon className={css.addIcon} iconID='add' />
          <FormattedMessage id="TopbarMobileMenu.addItem" />
        </NamedLink>

        <span name="LoginPage"
          className={css.howMoochWorksLink}
          onClick={() => setIsShowAdditionalLinks(!isShowAdditionalLinks)}
        >
          <FormattedMessage id="TopbarMobileMenu.howMoochWorks" />
          <Icon className={css.arrowIcon} iconID='arrowDown' />
        </span>
        {isShowAdditionalLinks && (
          <>
            <NamedLink className={css.link} name="CMSPage" params={{ pageId: CMSPageId.forRenters }}>
              <FormattedMessage id="TopbarMobileMenu.forRenters" />
            </NamedLink>
            <NamedLink className={css.link} name="CMSPage" params={{ pageId: CMSPageId.forVendors }} >
              <FormattedMessage id="TopbarMobileMenu.forVendors" />
            </NamedLink>
          </>
        )}
      </div>
      {isAuthenticated && (
        <>
          <span className={css.spacer}></span>
          <div className={css.settings}>
            <span className={css.spacer}></span>
            <NamedLink className={css.link} name="ProfileSettingsPage">
              <FormattedMessage id="TopbarMobileMenu.profileSettingsLink" />
            </NamedLink>
            <NamedLink className={css.link} name="AccountSettingsPage">
              <FormattedMessage id="TopbarMobileMenu.accountSettingsLink" />
            </NamedLink>
            <InlineTextButton className={css.link} onClick={onLogout}>
              <FormattedMessage id="TopbarMobileMenu.logoutLink" />
            </InlineTextButton>
          </div>
        </>
      )}
    </div >
  );
};

TopbarMobileMenu.defaultProps = { currentUser: null, notificationCount: 0, currentPage: null };

const { bool, func, number, string } = PropTypes;

TopbarMobileMenu.propTypes = {
  isAuthenticated: bool.isRequired,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  notificationCount: number,
  onLogout: func.isRequired,
};

export default TopbarMobileMenu;
