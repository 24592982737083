import React, { useMemo } from 'react';
import { bool, func, object, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { ensureOwnListing } from '../../util/data';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ListingLink } from '../../components';
import { EditListingDescriptionForm } from '../../forms';

import css from './EditListingDescriptionPanel.module.css';
import { categories } from '../../category-config';
import { quantities } from '../../quantity-config';
import { getChildlessCategories } from './EditListingDescriptionPanel.helpers';

const EditListingDescriptionPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { description, title, publicData = {} } = currentListing.attributes;
  const initialValues = { title, description, category: publicData.category, quantityByDefault: publicData.quantityByDefault ?? 1 };

  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingDescriptionPanel.title"
      values={{ listingTitle: <ListingLink listing={listing} /> }}
    />
  ) : (
    <FormattedMessage id="EditListingDescriptionPanel.createListingTitle" />
  );

  const categoryOptions = useMemo(() => getChildlessCategories(categories), []);

  return (
    <div className={classes}>
      <h1 className={css.title}>{panelTitle}</h1>
      <EditListingDescriptionForm
        className={css.form}
        initialValues={initialValues}
        saveActionMsg={submitButtonText}
        onSubmit={values => {
          const { title, description, category, quantityByDefault } = values;
          const wasSeatsEdited = quantityByDefault !== initialValues.quantityByDefault;
          const seats = wasSeatsEdited ? Number(quantityByDefault) : initialValues.quantityByDefault;
          const quantityTag = quantities.find(item => item.min <= seats && item.max >= seats)?.key;
          const updateValues = wasSeatsEdited ?
            {
              title: title.trim(),
              description,
              publicData: {
                category,
                quantityByDefault: seats,
                quantityTag
              },
              availabilityPlan: {
                type: "availability-plan/day",
                entries: [
                  { dayOfWeek: 'mon', seats },
                  { dayOfWeek: 'tue', seats },
                  { dayOfWeek: 'wed', seats },
                  { dayOfWeek: 'thu', seats },
                  { dayOfWeek: 'fri', seats },
                  { dayOfWeek: 'sat', seats },
                  { dayOfWeek: 'sun', seats }
                ]
              }
            } : {
              title: title.trim(),
              description,
              publicData: { category },
            };
          onSubmit(updateValues);
        }}
        onChange={onChange}
        disabled={disabled}
        ready={ready}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
        categories={categoryOptions}
      />
    </div>
  );
};

EditListingDescriptionPanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  listing: null,
};

EditListingDescriptionPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingDescriptionPanel;
