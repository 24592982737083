import React from 'react';

const IconHexagon = props => {
    const { className } = props;
    
    return (
        <svg
            className={className}
            width="27"
            height="24"
            viewBox="0 0 27 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M20.5856 24L27 12L20.5856 1.34659e-06L6.86188 1.46818e-07L-8.58275e-07 12L6.86188 24L20.5856 24Z" fillRule="evenodd"/>
        </svg>
    );
};

export default IconHexagon;