import React from 'react';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import Decimal from 'decimal.js';
import { formatMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';
import config from '../../config';
import {
  propTypes,
  LINE_ITEM_DELIVERY_FEE,
} from '../../util/types';

import css from './BookingBreakdown.module.css';
import { getItemByCode } from '../../util/lineItemHelpers';

const LineItemDeliveryFeeMaybe = props => {
  const { transaction, intl } = props;

  const deliveryFee = getItemByCode(transaction, LINE_ITEM_DELIVERY_FEE);
  const formattedValue = deliveryFee ? formatMoney(intl, deliveryFee.lineTotal) : null;

  return formattedValue ? (
    <div className={css.lineItem}>
      <span className={css.itemLabel}>
        <FormattedMessage id="BookingBreakdown.deliveryFee" />
      </span>
      <span className={css.itemValue}>{formattedValue}</span>
    </div>
  ) : null;
};

LineItemDeliveryFeeMaybe.propTypes = {
  transaction: propTypes.transaction.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemDeliveryFeeMaybe;
