import React, { Component } from 'react';
import { bool, func, object, shape, string } from 'prop-types';
import { Field } from 'react-final-form';
import classNames from 'classnames';
import { ValidationError } from '../../components';

import css from './FieldZipInput.module.css';

class FieldZipInputComponent extends Component {
  render() {
    /* eslint-disable no-unused-vars */
    const {
      rootClassName,
      className,
      inputRootClass,
      customErrorText,
      id,
      label,
      input,
      meta,
      onUnmount,
      isUncontrolled,
      inputRef,
      disabled,
      ...rest
    } = this.props;
    /* eslint-enable no-unused-vars */

    if (label && !id) {
      throw new Error('id required when a label is given');
    }

    const { valid, invalid, touched, error } = meta;
    const errorText = customErrorText || error;
    const hasError = !!customErrorText || !!(touched && invalid && error);
    const fieldMeta = { touched: hasError, error: errorText };
    const { value: defaultValue, ...inputWithoutValue } = input;
    const refMaybe = inputRef ? { ref: inputRef } : {};

    const inputClasses =
      inputRootClass ||
      classNames(css.input, {
        [css.inputSuccess]: valid,
        [css.inputError]: hasError,
        [css.inputDisabled]: disabled
      });

    const inputProps = isUncontrolled
      ? {
        className: inputClasses,
        id,
        defaultValue,
        ...refMaybe,
        ...inputWithoutValue,
        ...rest,
      }
      : {
        className: inputClasses,
        id,
        ...refMaybe,
        ...input,
        ...rest
      };

    const handleKeyPress = (e) => {
      const key = String.fromCharCode(e.charCode);
      if (!/[0-9]/.test(key)) {
        if (e.preventDefault) e.preventDefault();
      }
    };

    const classes = classNames(rootClassName || css.root, className);
    return (
      <div className={classes}>
        {label ? <label htmlFor={id}>{label}</label> : null}
        <input {...inputProps} maxLength="6" onKeyPress={handleKeyPress} />
        <ValidationError fieldMeta={fieldMeta} />
      </div>
    );
  }
}

FieldZipInputComponent.defaultProps = {
  rootClassName: null,
  className: null,
  inputRootClass: null,
  onUnmount: null,
  customErrorText: null,
  id: null,
  label: null,
  isUncontrolled: false,
  inputRef: null,
  disabled: false
};

FieldZipInputComponent.propTypes = {
  rootClassName: string,
  className: string,
  inputRootClass: string,
  onUnmount: func,
  customErrorText: string,
  id: string,
  label: string,
  isUncontrolled: bool,
  inputRef: object,
  input: shape({
    onChange: func.isRequired,
  }).isRequired,
  meta: object.isRequired,
  disabled: bool.isRequired
};

class FieldZipInput extends Component {
  componentWillUnmount() {
    if (this.props.onUnmount) {
      this.props.onUnmount();
    }
  }

  render() {
    return <Field component={FieldZipInputComponent} {...this.props} />;
  }
}

export default FieldZipInput;
