import React from 'react';
import classNames from 'classnames';

import css from './TransactionPanel.module.css';

const AddressPickupMaybe = props => {
  const { className, rootClassName, isPickup = false, pickupAddress = {} } = props;
  const { city, state, street, zip } = pickupAddress;
  const checkIsEmpty = value => value ? `${value},` : '';
  const getFirstUpperCase = value => value.charAt(0).toUpperCase() + value?.slice(1);
  const fullAddress = `${checkIsEmpty(street)} ${getFirstUpperCase(checkIsEmpty(city))} ${checkIsEmpty(state)} ${zip || ''}`.trim();

  const classes = classNames(rootClassName || css.address, className);
  return isPickup && fullAddress ? (
    <p className={classes}>{fullAddress}</p>
  ) : null;
};

export default AddressPickupMaybe;
