import React from 'react';
import { object } from 'prop-types';
import { RentalCategoryItem } from '../../components';
import { menuCategories } from '../../category-home-config';

import css from './RentalCategories.module.css';

const RentalCategories = props => {
  const { title } = props;
  const rentals = [{ key: 'rentals', label: 'Rentals', }];
  const categories = [...menuCategories, ...rentals];

  return (
    <>
      <div className={css.categoriesTitle}>{title.content.toUpperCase()}</div>
      <div className={css.categoriesWrapper}>
        {categories.map((item, index) => <RentalCategoryItem key={item.key} category={item} isOdd={index % 2 === 0} />)}
      </div>
    </>
  );
}

RentalCategories.defaultProps = {
  title: null
};

RentalCategories.propTypes = {
  title: object
}

export default RentalCategories;
