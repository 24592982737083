export const menuCategories = [
  {
    key: 'audio-visual',
    label: 'AV',
  },
  {
    key: 'chairs',
    label: 'Chairs',
  },
  {
    key: 'tables',
    label: 'Tables',
  },
  {
    key: 'vases',
    label: 'Vases',
  },
  {
    key: 'candles',
    label: 'Candles',
  },
  {
    key: 'dinnerware',
    label: 'Dinnerware',
  },
  {
    key: 'pillows',
    label: 'Throw pillows',
  },
  {
    key: 'glasses',
    label: 'Glasses',
  },
  {
    key: 'projectors',
    label: 'Projectors',
  },
  {
    key: 'signs',
    label: 'Signs',
  },
  {
    key: 'tents',
    label: 'Tents',
  },
  {
    key: 'loveSeat',
    label: 'Love Seat',
  },
  {
    key: 'umbrellas',
    label: 'Umbrellas',
  },
  {
    key: 'rugs',
    label: 'Rugs',
  },
  {
    key: 'instruments',
    label: 'Instruments',
  }
];
