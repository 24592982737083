import React from 'react';
import { range } from "lodash";
import { bool, number, string } from "prop-types";
import { useMemo } from "react";

import css from './FieldQuantitySelector.module.css';
import { useForm } from 'react-final-form';

export const FieldQuantitySelector = props => {
  const { name, maxValue, defaultValue } = props;
  const { change, value } = useForm();

  if (!maxValue || maxValue <= 0)
    return null;

  const quantities = useMemo(() => range(1, maxValue + 1), [maxValue]);

  return <select
    id={name}
    name={name}
    value={value}
    defaultValue={defaultValue}
    className={css.quantitySelector}
    onChange={e => change(name, Number(e.target.value))}
  >
    {quantities.map(quantity =>
      <option key={quantity} value={quantity}>{quantity}</option>
    )}
  </select>;
}

FieldQuantitySelector.defaultProps = {
  name: null,
  maxValue: 1,
  defaultValue: 1,
};

FieldQuantitySelector.propTypes = {
  name: string,
  maxValue: number,
  defaultValue: number,
};

export default FieldQuantitySelector;
