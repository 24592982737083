import React from 'react';
import { bool, node, string } from 'prop-types';
import classNames from 'classnames';
import { Field } from 'react-final-form';

import css from './FieldRadioButton.module.css';

const Icon = props => {
  const { className, showAsRequired, buttonType, selected } = props;
  return (
    <div>
      {buttonType === 'box' ?
        (
          <svg className={className} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="16" height="16" rx="2" className={classNames(css.checked, { [css.success]: selected })} />
            <path fillRule="evenodd" clipRule="evenodd" className={classNames(css.checkMark, { [css.success]: selected })}
              d="M13.0568 4.69192C13.1796 4.81496 13.2486 4.98177 13.2486 5.15567C13.2486 5.32958 13.1796 5.49637 13.0568 5.61942L6.71301 11.9632C6.58996 12.086 6.42317 12.1551 6.24926 12.1551C6.07535 12.1551 5.90855 12.086 5.78551 11.9632L2.94176 9.11942C2.82584 8.99501 2.76273 8.83048 2.76573 8.66046C2.76873 8.49045 2.8376 8.32824 2.95784 8.208C3.07808 8.08776 3.24029 8.01889 3.4103 8.01589C3.58032 8.01289 3.74485 8.076 3.86926 8.19192L6.24926 10.5719L12.1293 4.69192C12.2523 4.56903 12.4191 4.5 12.593 4.5C12.7669 4.5 12.9337 4.56903 13.0568 4.69192Z" />
            <rect x="0.5" y="0.5" width="15" height="15" rx="1.5" className={classNames(css.box, { [css.success]: selected })} />
          </svg>
        ) : (
          <svg className={className} width="14" height="14" xmlns="http://www.w3.org/2000/svg">
            <circle className={showAsRequired ? css.required : css.notChecked} cx="5" cy="19" r="6" transform="translate(2 -12)" strokeWidth="2" fill="none" fillRule="evenodd" />
            <g className={css.checked} transform="translate(2 -12)" fill="none" fillRule="evenodd">
              <circle strokeWidth="2" cx="5" cy="19" r="6" />
              <circle fill="#FFF" fillRule="nonzero" cx="5" cy="19" r="3" />
            </g>
          </svg>
        )}
    </div>
  );
};

Icon.defaultProps = { className: null, selected: false };
Icon.propTypes = { className: string, selected: bool };

const FieldRadioButtonComponent = props => {
  const { id, rootClassName, className, svgClassName, label, showAsRequired, buttonType, ...rest } = props;
  const { checked } = props;

  const classes = classNames(rootClassName || css.root, className);
  const radioButtonProps = {
    id,
    className: buttonType === 'box' ? css.inputBox : css.input,
    component: 'input',
    type: 'radio',
    ...rest,
  };

  return (
    <span className={classes}>
      <Field {...radioButtonProps} />
      <label htmlFor={id} className={css.label}>
        <span className={css.radioButtonWrapper}>
          <Icon className={svgClassName}
            showAsRequired={showAsRequired}
            buttonType={buttonType}
            selected={checked} />
        </span>
        <span className={css.text}>{label}</span>
      </label>
    </span>
  );
};

FieldRadioButtonComponent.defaultProps = {
  className: null,
  rootClassName: null,
  svgClassName: null,
  label: null,
  buttonType: null,
  checked: false
};

FieldRadioButtonComponent.propTypes = {
  className: string,
  rootClassName: string,
  svgClassName: string,

  // Id is needed to connect the label with input.
  id: string.isRequired,
  label: node,

  // Name groups several RadioButtones to an array of selected values
  name: string.isRequired,

  // RadioButton needs a value that is passed forward when user checks the RadioButton
  value: string.isRequired,

  buttonType: string,
  checked: bool
};

export default FieldRadioButtonComponent;
