import React from 'react';
import { object } from 'prop-types';
import { Icon, NamedLink } from '../../components';
import { eventsBlocks, eventsGrid } from '../../events-config';
import babyShowers from '../../assets/events/baby-showers.jpg'
import dinnerParty from '../../assets/events/dinner-party.jpg'
import picnicParty from '../../assets/events/picnic-party.jpg'
import wedding from '../../assets/events/wedding.jpg'

import css from './SectionEvents.module.css';

const SectionEvents = props => {
  const { details } = props;
  const { title, description } = details;
  const getLink = e => e && `event-${e}`;

  const renderEventsBlocks = item => {
    const style = { backgroundColor: item.background };
    const getPicture = e => {
      switch (e) {
        case 'baby-showers':
          return babyShowers;
        case 'dinner-party':
          return dinnerParty;
        case 'picnic-party':
          return picnicParty;
        default:
          return wedding;
      }
    }

    return (
      <NamedLink
        key={item.key}
        className={css.eventsBlocksItem}
        style={style}
        name="CMSPage"
        params={{ pageId: getLink(item.key) }}
      >
        <img src={getPicture(item.key)} className={css.eventsBlocksPicture} alt={item.label} />
        {item.label}
      </NamedLink>
    );
  }

  const renderEventsGrid = (item, isOdd) =>
    <NamedLink
      key={item.key}
      className={isOdd ? css.oddEventGridItem : css.evenEventGridItem}
      name="CMSPage"
      params={{ pageId: getLink(item.key) }}
    >
      <Icon className={css.eventsGridIcon} iconID={item.key} source='events' />
      {item.label}
    </NamedLink>

  return (
    <div className={css.sectionWrapper}>
      <div className={css.sectionTitle}>{title.content}</div>
      <div className={css.sectionDescription}>{description.content}</div>
      <div className={css.eventsBlocksWrapper}>
        {eventsBlocks.map(renderEventsBlocks)}
      </div>
      <div className={css.eventsGridWrapper}>
        {eventsGrid.map((item, index) => renderEventsGrid(item, index % 2 === 0))}
      </div>
    </div>
  );
}

SectionEvents.defaultProps = {
  details: null
};

SectionEvents.propTypes = {
  details: object
}

export default SectionEvents;
