import { LINE_ITEM_DAY, LINE_ITEM_DELIVERY_FEE } from "./types";

export const getItemByCode = (transaction, code) =>
    transaction.attributes.lineItems
        ? transaction.attributes.lineItems.find(lineItem =>
            lineItem.code === code
        )
        : null;

export const getItemByCodeWithReversal = (transaction, code, reversal) =>
    transaction.attributes.lineItems.find(lineItem =>
        lineItem.code === code && (!!lineItem.reversal) === reversal
    );

export const getDailyItem = (transaction) =>
    getItemByCode(transaction, LINE_ITEM_DAY);

export const getDeliveryFeeItem = (transaction) =>
    getItemByCode(transaction, LINE_ITEM_DELIVERY_FEE);