import React from 'react';
import { object } from 'prop-types';
import config from '../../config';
import { FormattedMessage } from '../../util/reactIntl';
import { NamedLink } from '../../components';

import css from './HeroSection.module.css';


const HeroSection = props => {
  const { details } = props;
  const { description } = details;
  const { heroTitles, heroCategories, locationByDefault } = config;
  const { address, bounds } = locationByDefault;
  const renderTitles = heroTitles.map(word => <span key={word}>{word}</span>);
  const renderCategories = heroCategories.map(category => <span key={category}>{category}</span>);

  return (
    <div className={css.heroWrapper}>
      <div className={css.heroTitle}>{renderTitles}</div>
      <div className={css.heroCategories}>{renderCategories}</div>
      <div className={css.heroDescription}>{description.content}</div>
      <div className={css.heroButtons}>
        <NamedLink
          className={css.heroButtonBrowseRentals}
          name="SearchPage"
          to={{ search: `address=${address}&bounds=${bounds}` }}
        >
          <FormattedMessage id="Hero.browseRentals" />
        </NamedLink>
        <NamedLink
          className={css.heroButtonAddItem}
          name="NewListingPage"
        >
          <FormattedMessage id="Hero.addItem" />
        </NamedLink>
      </div>
    </div>
  );
}

HeroSection.defaultProps = {
  details: null
};

HeroSection.propTypes = {
  details: object
}

export default HeroSection;
