import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { func, shape, string, oneOfType, bool } from 'prop-types';
import config from '../../config';
import { FormattedMessage } from '../../util/reactIntl';
import { Icon } from '../../components';
import CategoryPredictionsList from './CategoryPredictionsList';

import css from './KeywordTopbarSearch.module.css';

const KEY_CODE_ESC = 27;
const KEY_CODE_ENTER = 13;
const TOUCH_TAP_RADIUS = 5; // Movement within 5px from touch start is considered a tap

const currentValue = (props) => {
  const value = props.input.value || {};
  const { search = '' } = value;
  return { search };
};

const KeywordTopbarSearchComponent = props => {
  const {
    rootClassName,
    inputClassName,
    className,
    predictionsClassName,
    iconClassName,
    placeholder,
    input,
    inputRef,
    isMobile,
    onClose
  } = props;
  const [touchStartedFrom, setTouchStartedFrom] = useState(null);
  const [isSwipe, setIsSwipe] = useState(false);
  const [hasFocused, setHasFocused] = useState(false);
  const [predictions, setPredictions] = useState(null);
  const [highlightedIndex] = useState(-1);

  useEffect(() => {
    if (!hasFocused) setPredictions(null);
  }, [hasFocused]);

  const allCategories = config.custom.filters.find((item) => item.id === 'category').config.options;

  const getCategoryPredictionsList = (value) => {
    if (!value) {
      setPredictions(null);
      return
    };
    const searchValue = value.toLowerCase();
    const predictionsList = allCategories.filter((item) => item.label.toLowerCase().includes(searchValue));
    setPredictions(predictionsList);
  }

  const onChange = (e) => {
    const onChange = props.input.onChange;
    const newValue = e.target.value;
    getCategoryPredictionsList(newValue);
    onChange({
      search: newValue,
    });
  }

  const handleOnSubmit = () => {
    const onSubmit = input.onSubmit;
    const newValue = input.value;
    onSubmit({
      ...newValue,
    });
  }

  const onClick = () => {
    if (!input.value || input.value?.search?.length === 0) {
      predictions ? setPredictions(null) : setPredictions(allCategories);
    }
  };

  const handleOnSelect = (values) => {
    const onSelectCategory = input.onSelectCategory;
    onSelectCategory(values);
  }

  const handleOnBlur = () => {
    input.onBlur(props.input.value = '');
    setHasFocused(false);
  }

  const handleOnFocus = () => setHasFocused(true);

  const onKeyDown = (e) => {
    if (e.keyCode === KEY_CODE_ESC) {
      e.preventDefault();
      setPredictions(null);
    } else if (e.keyCode === KEY_CODE_ENTER) {
      e.preventDefault();
      const newValue = e.target.value;
      if (newValue) {
        handleOnSubmit();
      }
    }
  }

  const handlePredictionsSelectStart = (touchCoordinates) => {
    setTouchStartedFrom(touchCoordinates);
    setIsSwipe(false);
  }

  const handlePredictionsSelectMove = (touchCoordinates) => {
    const isSwipe = !!touchStartedFrom
      ? Math.abs(touchStartedFrom.y - touchCoordinates.y) > TOUCH_TAP_RADIUS
      : false;
    setIsSwipe(isSwipe);
  }

  const handlePredictionsSelectEnd = (prediction) => {
    if (!isSwipe) {
      handleOnSelect(prediction);
    } else {
      setTouchStartedFrom(null);
      setIsSwipe(false);
    }
    input.onBlur(currentValue(props));
  }

  const { search } = currentValue(props);
  const rootClass = classNames(rootClassName || css.root, className);
  const inputClass = classNames(inputClassName || css.input);
  const predictionsClass = classNames(predictionsClassName);

  return (
    <div className={rootClass}>
      <input
        className={inputClass}
        type="search"
        autoComplete="off"
        placeholder={placeholder}
        name="keywords"
        value={search}
        autoFocus={isMobile}
        onChange={onChange}
        onClick={onClick}
        onKeyDown={onKeyDown}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
        ref={node => {
          if (inputRef) {
            inputRef(node);
          }
        }}
      />
      <div className={iconClassName} onClick={handleOnSubmit}>
        <Icon className={css.loupeIcon} iconID="loupe" />
      </div>
      {isMobile && <div className={css.cancel} onClick={onClose}><FormattedMessage id="KeywordTopbarSearch.cancel" /></div>}
      {predictions ? (
        <CategoryPredictionsList
          rootClassName={predictionsClass}
          predictions={predictions}
          highlightedIndex={highlightedIndex}
          onSelectCategory={handleOnSelect}
          onSelectStart={handlePredictionsSelectStart}
          onSelectMove={handlePredictionsSelectMove}
          onSelectEnd={handlePredictionsSelectEnd}
        />
      ) : null}
    </div>
  );

};

KeywordTopbarSearchComponent.defaultProps = {
  rootClassName: null,
  inputClassName: null,
  predictionsClassName: null,
  className: null,
  iconClassName: null,
  placeholder: null,
  inputRef: null,
  isMobile: false
};

KeywordTopbarSearchComponent.propTypes = {
  rootClassName: string,
  inputClassName: string,
  className: string,
  predictionsClassName: string,
  iconClassName: string,
  placeholder: string,
  input: shape({
    name: string.isRequired,
    value: oneOfType([
      shape({
        search: string,
      }),
      string,
    ]),
    onSubmit: func.isRequired,
    onSelectCategory: func.isRequired,
  }).isRequired,
  inputRef: func,
  isMobile: bool,
  onClose: func
};

export default KeywordTopbarSearchComponent;
