import React from 'react';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import {
  propTypes,
  LINE_ITEM_SERVICE_FEE,
} from '../../util/types';

import css from './BookingBreakdown.module.css';
import { getItemByCode } from '../../util/lineItemHelpers';

const LineItemServiceFeeMaybe = props => {
  const { transaction, intl } = props;

  const serviceFee = getItemByCode(transaction, LINE_ITEM_SERVICE_FEE);
  const formattedValue = serviceFee ? formatMoney(intl, serviceFee.lineTotal) : null;

  return formattedValue ? (
    <div className={css.lineItem}>
      <span className={css.itemLabel}>
        <FormattedMessage id="BookingBreakdown.serviceFee" />
      </span>
      <span className={css.itemValue}>{formattedValue}</span>
    </div>
  ) : null;
};

LineItemServiceFeeMaybe.propTypes = {
  transaction: propTypes.transaction.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemServiceFeeMaybe;
