export const quantities = [
    {
        key: '1-10',
        label: '1 - 10',
        min: 1,
        max: 10,
    },
    {
        key: '11-50',
        label: '11 - 50',
        min: 11,
        max: 50,
    },
    {
        key: '51-100',
        label: '51 - 100',
        min: 51,
        max: 100,
    },
    {
        key: '101+',
        label: '> 100',
        min: 101,
        max: 100000000
    },
];