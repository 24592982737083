const deg2rad = (deg) => {
    return deg * (Math.PI/180)
}

const formatMiles = (miles) => {
    if (miles > 10) {
        return Math.round(miles);
    } else {
        return Math.round(miles * 10) / 10;
    }
}

export const calculateDistanceInMiles = (currentLat, currentLng, targetLat, targetLng) => {
    if (!(currentLat && currentLng && targetLat && targetLng)) return;
    var R = 3958.756; // Radius of the earth in miles
    var dLat = deg2rad(targetLat - currentLat);  // deg2rad below
    var dLon = deg2rad(targetLng - currentLng); 
    var a = 
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(currentLat)) * Math.cos(deg2rad(targetLat)) * 
      Math.sin(dLon / 2) * Math.sin(dLon / 2)
      ; 
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a)); 
    var d = R * c; // Distance in miles
    return formatMiles(d);
}