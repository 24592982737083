import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { Button, NamedLink } from '../../components';
import { bool, string } from 'prop-types';

import css from './EditListingButtonBar.module.css';

const EditListingButtonBar = props => {
  const {
    saveActionMsg,
    submitInProgress,
    submitDisabled,
    submitReady,
  } = props;

  return (
    <div className={css.buttonsWrapper}>
      <NamedLink
        name="ManageListingsPage"
        className={css.buttonToListings}
      >
        <FormattedMessage id="EditListingPage.returnToListings" />
      </NamedLink>
      <Button
        className={css.submitButton}
        type="submit"
        inProgress={submitInProgress}
        disabled={submitDisabled}
        ready={submitReady}
      >
        {saveActionMsg}
      </Button>
    </div>
  );
};

EditListingButtonBar.propTypes = {
  saveActionMsg: string.isRequired,
  submitInProgress: bool.isRequired,
  submitDisabled: bool.isRequired,
  submitReady: bool.isRequired,
};

export default EditListingButtonBar;
