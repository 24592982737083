import React from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { maxLength, required, composeValidators, numberAtLeast, numberNotGreater } from '../../util/validators';
import { Form, FieldTextInput, EditListingButtonBar } from '../../components';
import CustomCategorySelectFieldMaybe from './CustomCategorySelectFieldMaybe';

import css from './EditListingDescriptionForm.module.css';

const TITLE_MAX_LENGTH = 60;

const EditListingDescriptionFormComponent = props => (
  <FinalForm
    {...props}
    render={formRenderProps => {
      const {
        categories,
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
      } = formRenderProps;

      const titleMessage = intl.formatMessage({ id: 'EditListingDescriptionForm.title' });
      const titlePlaceholderMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.titlePlaceholder',
      });
      const titleRequiredMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.titleRequired',
      });
      const maxLengthMessage = intl.formatMessage(
        { id: 'EditListingDescriptionForm.maxLength' },
        {
          maxLength: TITLE_MAX_LENGTH,
        }
      );

      const descriptionMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.description',
      });
      const descriptionPlaceholderMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.descriptionPlaceholder',
      });
      const maxLength60Message = maxLength(maxLengthMessage, TITLE_MAX_LENGTH);
      const descriptionRequiredMessage = intl.formatMessage({
        id: 'EditListingDescriptionForm.descriptionRequired',
      });

      const { updateListingError, createListingDraftError, showListingsError } = fetchErrors || {};
      const errorMessageUpdateListing = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDescriptionForm.updateFailed" />
        </p>
      ) : null;

      // This error happens only on first tab (of EditListingWizard)
      const errorMessageCreateListingDraft = createListingDraftError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDescriptionForm.createListingDraftError" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDescriptionForm.showListingFailed" />
        </p>
      ) : null;

      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;

      const minQuantityByDefault = 1;
      const maxQuantityByDefault = 1000;
      const quantityLabel = intl.formatMessage({ id: 'EditListingDescriptionForm.quantityLabel' });
      const quantityPlaceholderMessage = intl.formatMessage({ id: 'EditListingDescriptionForm.quantityInputPlaceholder' }, {
        minQuantityByDefault,
        maxQuantityByDefault,
      });
      const quantityRequired = required(intl.formatMessage({ id: 'EditListingDescriptionForm.quantityRequired', }));
      const minQuantityRequired = numberAtLeast(
        intl.formatMessage({ id: 'EditListingDescriptionForm.quantityTooLow' },
          {
            minQuantityByDefault: minQuantityByDefault
          }),
        minQuantityByDefault
      );
      const maxQuantityRequired = numberNotGreater(
        intl.formatMessage({ id: 'EditListingDescriptionForm.quantityTooUp' },
          {
            maxQuantityByDefault: maxQuantityByDefault
          }),
          maxQuantityByDefault
      );
      const quantityValidators = minQuantityByDefault
        ? composeValidators(quantityRequired, minQuantityRequired, maxQuantityRequired)
        : quantityRequired;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessageCreateListingDraft}
          {errorMessageUpdateListing}
          {errorMessageShowListing}
          <FieldTextInput
            id="title"
            name="title"
            className={css.title}
            type="text"
            label={titleMessage}
            placeholder={titlePlaceholderMessage}
            maxLength={TITLE_MAX_LENGTH}
            validate={composeValidators(required(titleRequiredMessage), maxLength60Message)}
            autoFocus
          />

          <FieldTextInput
            id="description"
            name="description"
            className={css.description}
            type="textarea"
            label={descriptionMessage}
            placeholder={descriptionPlaceholderMessage}
            validate={composeValidators(required(descriptionRequiredMessage))}
          />

          <div className={css.categoryQuantityWrapper}>
            <CustomCategorySelectFieldMaybe
              id="category"
              name="category"
              categories={categories}
              intl={intl}
            />
            <FieldTextInput
              id="quantityByDefault"
              className={css.quantity}
              name="quantityByDefault"
              type="text"
              label={quantityLabel}
              placeholder={quantityPlaceholderMessage}
              validate={quantityValidators}
            />
          </div>

          <EditListingButtonBar
            submitInProgress={submitInProgress}
            submitDisabled={submitDisabled}
            submitReady={submitReady}
            saveActionMsg={saveActionMsg}
          />

        </Form>
      );
    }}
  />
);

EditListingDescriptionFormComponent.defaultProps = { className: null, fetchErrors: null };

EditListingDescriptionFormComponent.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  categories: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ),
};

export default compose(injectIntl)(EditListingDescriptionFormComponent);
