import React from 'react';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import {
  propTypes,
  LINE_ITEM_DAMAGE_FEE,
} from '../../util/types';

import css from './BookingBreakdown.module.css';
import { getItemByCode } from '../../util/lineItemHelpers';

const LineItemDamageFeeMaybe = props => {
  const { transaction, intl } = props;

  const damageFee = getItemByCode(transaction, LINE_ITEM_DAMAGE_FEE);
  const formattedValue = damageFee ? formatMoney(intl, damageFee.lineTotal) : null;

  return formattedValue ? (
    <div className={css.lineItem}>
      <span className={css.itemLabel}>
        <FormattedMessage id="BookingBreakdown.damageFee" />
      </span>
      <span className={css.itemValue}>{formattedValue}</span>
    </div>
  ) : null;
};

LineItemDamageFeeMaybe.propTypes = {
  transaction: propTypes.transaction.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemDamageFeeMaybe;
