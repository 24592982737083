import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form as FinalForm, Field } from 'react-final-form';
import classNames from 'classnames';
import { intlShape, injectIntl } from '../../util/reactIntl';
import { Form, KeywordTopbarSearch } from '../../components';

import css from './TopbarSearchKeywordsForm.module.css';

const identity = v => v;

class TopbarSearchKeywordsFormComponent extends Component {
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
    this.onSelectCategory = this.onSelectCategory.bind(this);
    this.searchInput = null;
  }

  onSubmit(keywords) {
    this.props.onSubmit({ keywords });
    if (this.searchInput) {
      this.searchInput.blur();
    }
  }

  onSelectCategory(category) {
    if (category) {
      this.props.onSelectCategory({ category });
    }
  }

  render() {
    return (
      <FinalForm
        {...this.props}
        render={formRenderProps => {
          const { rootClassName, className, desktopInputRoot, intl, isMobile, onClose } = formRenderProps;
          const classes = classNames(rootClassName, className);
          const desktopInputRootClass = desktopInputRoot || css.desktopInputRoot;

          return (
            <Form
              className={classes}
              enforcePagePreloadFor="SearchPage"
            >
              <Field
                name="keywords"
                format={identity}
                render={({ input, meta }) => {
                  const { onSubmit, onSelectCategory, ...restInput } = input;
                  const searchOnSubmit = value => this.onSubmit(value);
                  const selectCategory = value => this.onSelectCategory(value);
                  const searchInput = { ...restInput, onSubmit: searchOnSubmit, onSelectCategory: selectCategory };
                  return (
                    <KeywordTopbarSearch
                      className={isMobile ? css.mobileInputRoot : desktopInputRootClass}
                      iconClassName={isMobile ? css.mobileIcon : css.desktopIcon}
                      inputClassName={isMobile ? css.mobileInput : css.desktopInput}
                      predictionsClassName={isMobile ? css.mobilePredictions : css.desktopPredictions}
                      rootClassName={rootClassName}
                      placeholder={intl.formatMessage({ id: 'TopbarSearchKeywordsForm.placeholder' })}
                      closeOnBlur={!isMobile}
                      inputRef={node => {
                        this.searchInput = node;
                      }}
                      input={searchInput}
                      isMobile={isMobile}
                      onClose={onClose}
                    />
                  );
                }}
              />
            </Form>
          );
        }}
      />
    );
  }
}

const { func, string, bool } = PropTypes;

TopbarSearchKeywordsFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  desktopInputRoot: null,
  isMobile: false
};

TopbarSearchKeywordsFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  desktopInputRoot: string,
  onSubmit: func.isRequired,
  onSelectCategory: func.isRequired,
  isMobile: bool,
  intl: intlShape.isRequired,
  onClose: func
};

const TopbarSearchKeywordsForm = injectIntl(TopbarSearchKeywordsFormComponent);

export default TopbarSearchKeywordsForm;


