import React from 'react';
import Modal from './Modal';
import PropTypes from 'prop-types';

import css from './Modal.module.css';
import classNames from 'classnames';

const { node, string } = PropTypes;

const CloseableModalWithHeader = (props) => {
    const {
        children,
        title,
        subtitle,
        containerClassName,
        ...modalProps
    } = props;

    return (
        <Modal
            {...modalProps}
            containerClassName={classNames(css.closeableModalContainer, containerClassName)}
            contentClassName={css.closeableModalContent}
            closeButtonClassName={css.closeableModalCloseButton}
            usePortal
            showCloseButton
        >
            <div className={css.closeableModalHeader}>
                <div className={css.closeableModalTitle}>{title}</div>
                {subtitle && <div className={css.closeableModalSubTitle}>{subtitle}</div>}
            </div>
            <div className={css.closeableModalInnerContent}>
                {children}
            </div>
        </Modal>
    )
}

CloseableModalWithHeader.defaultProps = {
    children: null,
    containerClassName: null,
};
  
CloseableModalWithHeader.propTypes = {
    children: node,
    title: string.isRequired,
    subtitle: string,
    containerClassName: string,
};

export default CloseableModalWithHeader;