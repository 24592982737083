import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import config from '../../config';
import { Icon } from '../../components';
import LogoImage from '../../assets/logo.png';
import IconLogo from '../../assets/yellowBlackLogo.png';
import css from './Logo.module.css';

const Logo = props => {
  const { className, format, ...rest } = props;
  const mobileClasses = classNames(css.logoMobile, className);
  const polygonClasses = classNames(css.logoPolygon, className);

  if (format === 'desktop') {
    return <img className={className} src={LogoImage} alt={config.siteTitle} {...rest} />;
  }

  if (format === 'polygon') {
    return (
      <div className={polygonClasses}>
        <Icon className={css.polygon} iconID="polygon" />
        <Icon className={css.letter} iconID="letterLogo" />
      </div>
    );
  }

  if (format === 'icon') {
    return <img className={css.icon} src={IconLogo} alt={config.siteTitle} {...rest} />;
  }

  return <img className={mobileClasses} src={LogoImage} alt={config.siteTitle} {...rest} />;
};

const { oneOf, string } = PropTypes;

Logo.defaultProps = {
  className: null,
  format: 'desktop',
};

Logo.propTypes = {
  className: string,
  format: oneOf(['desktop', 'mobile', 'polygon']),
};

export default Logo;
