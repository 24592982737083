import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import icons from '../../assets/icons.svg';
import categoriesIcons from '../../assets/categoriesIcons.svg';
import eventIcons from '../../assets/eventsIcons.svg';
import benefitsIcons from '../../assets/benefitsIcons.svg'
import numbersIcons from '../../assets/numbersIcons.svg'

const { string } = PropTypes;

const Icon = props => {
  const { rootClassName, className, iconID, source } = props;
  const classes = classNames(rootClassName, className);
  const path = source => {
    switch (source) {
      case 'categories':
        return `${categoriesIcons}#${iconID}`;
      case 'events':
        return `${eventIcons}#${iconID}`;
      case 'benefits':
        return `${benefitsIcons}#${iconID}`;
      case 'numbers':
        return `${numbersIcons}#${iconID}`;
      default:
        return `${icons}#${iconID}`;
    }
  };

  return (
    <svg className={classes}>
      <use href={path(source)} />
    </svg>);
};

Icon.defaultProps = {
  rootClassName: null,
  className: null,
  iconID: null,
  source: null,
};

Icon.propTypes = {
  rootClassName: string,
  className: string,
  iconID: string,
  source: string
};

export default Icon;





