export const categories = [
  {
    key: 'event-furniture',
    label: 'Event Furniture',
    items: [
      { key: 'chairs', label: 'Chairs' },
      { key: 'end-tables', label: 'End Tables' },
      { key: 'ottomans', label: 'Ottomans' },
      { key: 'tables', label: 'Tables' },
      { key: 'instruments', label: 'Instruments' },
      { key: 'loveSeat', label: 'Love Seat' },
      { key: 'sofas', label: 'Sofas' },
      { key: 'exhibition-booth', label: 'Exhibition booth' },
      { key: 'seating', label: 'Seating' }
    ]
  },
  {
    key: 'tableware',
    label: 'Tableware',
    items: [
      { key: 'dinnerware', label: 'Dinnerware' },
      { key: 'flatware', label: 'Flatware' },
      { key: 'glassware', label: 'Glassware' },
      { key: 'servingware', label: 'Servingware' },
      { key: 'utensils', label: 'Utensils' },
      { key: 'serving-trays', label: 'Serving trays' }
    ]
  },
  {
    key: 'decorative-elements',
    label: 'Decorative Elements',
    items: [
      { key: 'backdrops', label: 'Backdrops' },
      { key: 'candles', label: 'Candles' },
      { key: 'centerpieces', label: 'Centerpieces' },
      { key: 'chair-covers', label: 'Chair covers' },
      { key: 'lighting-fixtures', label: 'Lighting' },
      { key: 'linens', label: 'Table linens' },
      { key: 'mirrors', label: 'Mirrors' },
      { key: 'pillows', label: 'Pillows' },
      { key: 'decorative-pillows', label: 'Decorative pillows' },
      { key: 'pipe-drapes', label: 'Pipe & Drapes' },
      { key: 'rugs', label: 'Rugs' },
      { key: 'seasonal', label: 'Seasonal' },
      { key: 'signs', label: 'Signs' },
      { key: 'vases', label: 'Vases' },
      { key: 'curtains', label: 'Curtains' },
      { key: 'paintings', label: 'Paintings' },
      { key: 'napkins', label: 'Napkins' },
      { key: 'decorations', label: 'Decorations' }
    ]
  },
  {
    key: 'food-beverage-equipment',
    label: 'Food and Beverage Equipment',
    items: [
      { key: 'back-bar', label: 'Back Bar' },
      { key: 'bar-accessories', label: 'Bar accessories' },
      { key: 'bars', label: 'Bars' },
      { key: 'beverage-dispensers', label: 'Beverage dispensers' },
      { key: 'buffet-tables', label: 'Buffet tables' },
      { key: 'chafing-dishes', label: 'Chafing dishes' },
      { key: 'coffee-makers', label: 'Coffee makers' },
      { key: 'heating-elements', label: 'Heating Elements' }
    ]
  },
  {
    key: 'flooring-staging',
    label: 'Flooring and Staging',
    items: [
      { key: 'carpeting-turf', label: 'Carpeting & turf' },
      { key: 'dance-floors', label: 'Dance floors' },
      { key: 'staging', label: 'Staging' },
      { key: 'disco-balls', label: 'Disco balls' }
    ]
  },
  {
    key: 'outdoor-equipment',
    label: 'Outdoor Equipment',
    items: [
      { key: 'canopies', label: 'Canopies' },
      { key: 'grills-bbqs', label: 'Grills & BBQs' },
      { key: 'heaters', label: 'Heaters' },
      { key: 'structures', label: 'Structures' },
      { key: 'tents', label: 'Tents' },
      { key: 'umbrellas', label: 'Umbrellas' },
      { key: 'blankets', label: 'Blankets' }
    ]
  },
  {
    key: 'games-entertainment',
    label: 'Games and Entertainment',
    items: [
      { key: 'games', label: 'Games and activities' },
      { key: 'inflatables', label: 'Inflatables' },
      { key: 'karaoke-machines', label: 'Karaoke machines' },
      { key: 'photo-backdrops', label: 'Photo backdrops' },
      { key: 'vendor-carts', label: 'Vendor Carts' },
      { key: 'board-games', label: 'Board games' },
      { key: 'guessing-games', label: 'Guessing games' }
    ]
  },
  {
    key: 'kids-items',
    label: 'Kids Items',
    items: [
      { key: 'decor', label: 'Décor' },
      { key: 'fun', label: 'Fun' },
      { key: 'furniture', label: 'Furniture' },
      { key: 'bubble-machine', label: 'Bubble machine' },
      { key: 'baby-themed-trivia', label: 'Baby-themed trivia' }
    ]
  },
  {
    key: 'audio-visual-equipment', label: 'Audio/Visual Equipment',
    items: [
      { key: 'audio-visual', label: 'AV' },
      { key: 'projectors', label: 'Projectors' },
      { key: 'music', label: 'Music' }
    ]
  },
  {
    key: 'miscellaneous',
    label: 'Miscellaneous',
    items: [
      { key: 'coat-racks', label: 'Coat racks' },
      { key: 'easels', label: 'Easels' },
      { key: 'fencing-barriers', label: 'Fencing & barriers' },
      { key: 'fountains', label: 'Fountains' },
      { key: 'chargers', label: 'Chargers' },
      { key: 'plants', label: 'Plants' },
      { key: 'flowers', label: 'Flowers' },
      { key: 'podiums', label: 'Podiums' },
      { key: 'vintage', label: 'Vintage' }
    ]
  },
];
