import React from 'react';
import { compose } from 'redux';
import { intlShape, injectIntl } from '../../util/reactIntl';
import { FieldSelect } from '../../components';
import { findOptionsForSelectFilter } from '../../util/search';
import config from '../../config';

const FieldSelectState = props => {
  const { id, name, className, intl, disabled = false } = props;
  const states = findOptionsForSelectFilter('state', config.custom.filters);
  const statesLabel = intl.formatMessage({ id: 'FieldSelectState.statesLabel' });

  return states ? (
    <FieldSelect
      id={id}
      name={name}
      className={className}
      label={statesLabel}
      disabled={disabled}
    >
      {states.map(c => (
        <option key={c.key} value={c.key}>
          {c.label}
        </option>
      ))}
    </FieldSelect>
  ) : null;
};

FieldSelectState.propTypes = {
  intl: intlShape.isRequired,
};

export default compose(injectIntl)(FieldSelectState);
