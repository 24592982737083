import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import config from '../../config';
import { types as sdkTypes } from '../../util/sdkLoader';
import { FormattedMessage } from '../../util/reactIntl';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ensureOwnListing } from '../../util/data';
import { ListingLink } from '../../components';
import { EditListingDeliveryForm } from '../../forms';

import css from './EditListingDeliveryPanel.module.css';

const { Money } = sdkTypes;
const DELIVERY_NAME = 'deliveryType';

const EditListingDeliveryPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { publicData = {} } = currentListing.attributes;
  const { deliveryType = [] } = publicData;
  const {
    deliveryFee = null,
    pickupAddress = {},
    deliveryRadius = '' } = publicData.delivery ?? {};
  const { city, state, street, zip } = pickupAddress;

  const findDelivery =
    useCallback(
      (delivery) =>
        (deliveryType.find((item) => item === delivery) ? [delivery] : [])
      , [deliveryType]
    );

  const initialValues = useMemo(() => ({
    ownerDelivery: findDelivery("ownerDelivery"),
    renterPickUp: findDelivery("renterPickUp"),
    deliveryFee: deliveryFee ? new Money(deliveryFee.amount, deliveryFee.currency) : null,
    deliveryRadius,
    city,
    state: state || config.stateByDefault,
    street,
    zip
  }), [findDelivery]);

  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingDeliveryPanel.title"
      values={{ listingTitle: <ListingLink listing={listing} /> }}
    />
  ) : (
    <FormattedMessage id="EditListingDeliveryPanel.createListingTitle" />
  );

  return (
    <div className={classes}>
      <h1 className={css.title}>{panelTitle}</h1>
      <EditListingDeliveryForm
        className={css.form}
        name={DELIVERY_NAME}
        initialValues={initialValues}
        onSubmit={(values) => {
          const { ownerDelivery = [],
            renterPickUp = [],
            city,
            state,
            street,
            zip,
            deliveryFee,
            deliveryRadius } = values;
          const deliveryType = [...ownerDelivery, ...renterPickUp];
          const updatedValues = {
            publicData: {
              deliveryType,
              delivery: {
                pickupAddress: {
                  city,
                  state,
                  street,
                  zip
                },
                deliveryFee: deliveryFee
                  ? {
                    amount: deliveryFee.amount,
                    currency: deliveryFee.currency
                  }
                  : undefined,
                deliveryRadius
              }
            },
          };
          onSubmit(updatedValues);
        }}
        onChange={onChange}
        saveActionMsg={submitButtonText}
        disabled={disabled}
        ready={ready}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
        deliveryRadiusOptions={config.deliveryRadiusOptions}
        keepDirtyOnReinitialize
      />
      <div className={css.addressTip}>
        <FormattedMessage id="EditListingDeliveryPanel.addressTip" />
      </div>
    </div>
  );
};

const { func, object, string, bool } = PropTypes;

EditListingDeliveryPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
};

EditListingDeliveryPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingDeliveryPanel;
