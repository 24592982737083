export const eventsBlocks = [
  {
    key: 'baby-showers',
    label: 'Baby Showers',
    background: '#9a7272',
    categoriesSection: [['tables', 'chairs'], ['baby-themed-trivia', 'guessing-games'], ['signs', 'photo-backdrops']],
    additionalСategories: ['audio-visual', 'lighting-fixtures', 'umbrellas'],
  },
  {
    key: 'dinner-party',
    label: 'Dinner Party',
    background: '#1b2b36',
    categoriesSection: [['candles', 'vases', 'flowers'], ['napkins', 'chargers'], ['glassware']],
    additionalСategories: ['linens', 'lighting-fixtures'],
  },
  {
    key: 'wedding',
    label: 'Wedding',
    background: '#f39778',
    categoriesSection: [['tables', 'chairs'], ['linens', 'napkins'], ['flatware', 'dinnerware']],
    additionalСategories: ['audio-visual', 'tents', 'canopies', 'lighting-fixtures', 'decorations'],
  },
  {
    key: 'picnic-party',
    label: 'Picnic Party',
    background: '#909c7B',
    categoriesSection: [['blankets', 'chairs'], ['bar-accessories'], ['utensils', 'tableware']],
    additionalСategories: ['audio-visual', 'games', 'umbrellas'],
  }
];

export const eventsGrid = [
  {
    key: 'birthday',
    label: 'Birthday',
    categoriesSection: [['projectors', 'backdrops', 'karaoke-machines'], ['board-games'], ['lighting-fixtures', 'disco-balls']],
    additionalСategories: ['audio-visual', 'chairs', 'tables'],
  },
  {
    key: 'wedding-shower',
    label: 'Wedding shower',
    categoriesSection: [['signs', 'backdrops', 'flower-walls'], ['decorative-elements'], ['chairs', 'tables', 'sofas']],
    additionalСategories: ['audio-visual', 'umbrellas', 'rugs'],
  },
  {
    key: 'corporate-meetings',
    label: 'Corporate Event',
    categoriesSection: [['seating', 'tables'], ['audio-visual', 'staging'], ['backdrops', 'projectors']],
    additionalСategories: ['lighting-fixtures', 'music'],
  },
  {
    key: 'trade-show',
    label: 'Trade show',
    categoriesSection: [['exhibition-booth', 'tables'], ['curtains', 'backdrops'], ['signs']],
    additionalСategories: ['lighting-fixtures', 'seating', 'audio-visual'],
  },
  {
    key: 'holiday-party',
    label: 'Holiday party',
    categoriesSection: [['lighting-fixtures'], ['projectors', 'backdrops'], ['serving-trays']],
    additionalСategories: ['tables', 'chairs', 'audio-visual'],
  },
  {
    key: 'kids-birthday',
    label: 'Kids birthday',
    categoriesSection: [['signs', 'backdrops'], ['board-games'], ['bubble-machine']],
    additionalСategories: ['tables', 'chairs'],
  },
  {
    key: 'home-staging',
    label: 'Home staging',
    categoriesSection: [['chairs', 'tables'], ['decorative-pillows'], ['paintings', 'decorative-elements']],
    additionalСategories: ['lighting-fixtures', 'plants', 'rugs'],
  },
  {
    key: 'game-day',
    label: 'Game day',
    categoriesSection: [['chairs'], ['tables'], ['bar-accessories']],
    additionalСategories: ['canopies', 'heaters', 'glassware'],
  }
];
