import React, { useState, useEffect, useRef } from 'react';
import { useSwipeable } from "react-swipeable";
import { object } from 'prop-types';
import { Icon, ResponsiveImage } from '../../components';
import { testimonialsSettings } from '../../testimonial-config';

import css from './Testimonial.module.css';

const Testimonial = props => {
  const { details } = props;
  const { blocks } = details || {};

  const testimonials = blocks.map(item => {
    const delimiter = item.title.content.indexOf('*');
    return {
      image: item.media.image || {},
      author: item.title.content.substring(0, delimiter),
      value: item.text.content,
      whoIs: item.title.content.substring(delimiter + 1),
    }
  });

  const { maxAuthorNameLength, maxValueTestimonial, animationDelay } = testimonialsSettings;
  const [selected, setSelected] = useState(0);
  const [animate, setAnimate] = useState(false);
  const [slideData, setSlideData] = useState({});
  const testimonialRef = useRef();

  useEffect(() => {
    getNewTestimonial();
  }, [selected]);

  const flipTestimonial = (direction) => {
    if (testimonials) {
      setAnimate(true);
      setTimeout(() => {
        switch (direction) {
          case "Left":
            setSelected((selected + 1) % testimonials.length);
            break;
          case "Right":
            setSelected((selected + testimonials.length - 1) % testimonials.length);
            break;
          default:
            break;
        }
        setAnimate(false);
      }, animationDelay);
    }
  };

  const getNewTestimonial = () => {
    if (testimonials) {
      const currentTestimonials = testimonials[selected];
      const prevTestimonials = testimonials[(selected + testimonials.length - 1) % testimonials.length];
      const nextTestimonials = testimonials[(selected + 1) % testimonials.length];
      setSlideData({
        value: currentTestimonials?.value?.slice(0, maxValueTestimonial),
        author: currentTestimonials?.author?.slice(0, maxAuthorNameLength),
        whoIs: currentTestimonials?.whoIs,
        image: currentTestimonials?.image,
        imageVariantNames: Object.keys(currentTestimonials?.image.attributes.variants),
        prevImage: prevTestimonials?.image,
        prevImageVariantNames: Object.keys(prevTestimonials?.image.attributes.variants),
        nextImage: nextTestimonials?.image,
        nextImageVariantNames: Object.keys(nextTestimonials?.image.attributes.variants),
      });
    }
  };

  const refPassThrough = (el) => {
    handlers.ref(el);
    testimonialRef.current = el;
  };

  const handlers = useSwipeable({ onSwiped: (eventData) => flipTestimonial(eventData.dir) });

  const renderBubbles = () =>
    testimonials?.map((item, index) => <span key={`bubble_${index}`} className={index === selected ? css.bubbleSelected : css.bubble} />);

  const imageClass = animate ? css.testimonialImageAnimate : css.testimonialImage;

  const renderArrowButton = (className, direction) => (
    <button className={className} onClick={() => flipTestimonial(direction)}>
      <Icon className={css.polygon} iconID="polygon" />
      <Icon className={css.arrow} iconID='arrow' />
    </button>
  )

  return (
    <div className={css.testimonialsWrapper}>
      <div className={css.contentWrapper} ref={refPassThrough}>
        <div className={css.testimonialsImageWrapper}>
          {testimonials?.length > 1 && (
            <>
              <ResponsiveImage
                className={css.cacheImages}
                alt={''}
                image={slideData?.prevImage}
                variants={slideData?.prevImageVariantNames || []}
              />
              <ResponsiveImage
                className={css.cacheImages}
                alt={''}
                image={slideData?.nextImage}
                variants={slideData?.nextImageVariantNames || []}
              />
            </>
          )}
          <ResponsiveImage
            className={imageClass}
            alt={''}
            image={slideData?.image}
            variants={slideData?.imageVariantNames || []}
          />
        </div>
        <div className={css.valueWrapper}>
          <div className={css.author}>{`${slideData?.author}. says`}</div>
          <div className={css.value}>{slideData?.value}</div>
          <div className={css.whoIs}>{slideData?.whoIs}</div>
          <Icon className={css.icon} iconID='commas' />
          <div className={css.bubblesWrapper}>{renderBubbles()}</div>
        </div>
        <div className={css.buttonsWrapper}>
          {renderArrowButton(css.backButton, "Left")}
          {renderArrowButton(css.forwardButton, "Right")}
        </div>
      </div>
    </div>
  );
}

Testimonial.defaultProps = {
  details: null
};

Testimonial.propTypes = {
  details: object
}

export default Testimonial;
