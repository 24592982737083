import React, { useCallback, useState } from 'react';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import classNames from 'classnames';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import { PrimaryButton, SecondaryButton, Form, FieldTextInput, FieldRadioButton } from '..';
import * as validators from '../../util/validators';

import css from './TransactionPanel.module.css';

const CompletionProcess = props => {
  const {
    intl,
    completeInProgress,
    completeSaleError,
    onCompleteSale,
  } = props;

  const [showQuestion, setShowQuestion] = useState(false);
  const [hasProblem, setHasProblem] = useState(false);

  const problemMessage = intl.formatMessage({
    id: 'TransactionPanel.problemRequired',
  });
  const problemRequired = validators.required(problemMessage);

  const handleCancel = useCallback(() => {
    setShowQuestion(false);
    setHasProblem(false);
  }, []);

  const handleSubmit = useCallback(({ problemDescription }) => {
    onCompleteSale(problemDescription);
  }, [])

  const handleOnChange = useCallback((formValues) => {
    const { hasProblem } = formValues?.values;
    if (hasProblem) setHasProblem(JSON.parse(hasProblem));
  }, [])

  return (
    <div className={css.actionButtons}>
      {!showQuestion && (
        <div className={css.actionButtonWrapper}>
          <PrimaryButton
            onClick={() => { setShowQuestion(true); }}
          >
            <FormattedMessage id="TransactionPanel.checkInReturnedItems" />
          </PrimaryButton>
        </div>
      )}
      {showQuestion && (
        <FinalForm
          onSubmit={handleSubmit}
          render={fieldRenderProps => {
            const {
              handleSubmit: formHandleSubmit,
              invalid,
              inProgress: formInProgress
            } = fieldRenderProps;
            const inProgress = formInProgress || completeInProgress
            const submitDisabled = (invalid || inProgress) && hasProblem;

            return (
              <>
                {(
                  <Form onSubmit={formHandleSubmit}>
                    <FormSpy
                      subscription={{ values: true }}
                      onChange={values => handleOnChange(values)}
                    />
                    <div>
                      <FieldRadioButton
                        id="false"
                        name="hasProblem"
                        checked={!hasProblem}
                        label={intl.formatMessage({ id: 'TransactionPanel.itemsReturnedAsExpected' })}
                        value="false"
                        buttonType="box"
                        className={css.completionCheckInRadio}
                      />
                      <FieldRadioButton
                        id="true"
                        name="hasProblem"
                        checked={hasProblem}
                        label={intl.formatMessage({ id: 'TransactionPanel.problemWithReturnedItems' })}
                        value="true"
                        buttonType="box"
                        className={css.completionCheckInRadio}
                      />
                    </div>
                    {hasProblem && (
                      <div className={css.completionCheckInProblem}>
                        <FormattedMessage id="TransactionPanel.tellUsAboutProblem" />:
                        <FieldTextInput
                          id="problemDescription"
                          name="problemDescription"
                          type="textarea"
                          validate={problemRequired}
                          inputRootClass={css.completionProblem}
                        />
                      </div>
                    )}
                    <div className={classNames(css.actionButtonWrapper, css.completionActionButtons)}>
                      <SecondaryButton
                        onClick={handleCancel}
                      >
                        <FormattedMessage id="TransactionPanel.cancelButton" />
                      </SecondaryButton>
                      <PrimaryButton
                        type="submit"
                        inProgress={inProgress}
                        disabled={submitDisabled}
                      >
                        <FormattedMessage id="TransactionPanel.completeButton" />
                      </PrimaryButton>
                    </div>
                  </Form>
                )}
                {completeSaleError && (
                  <div className={css.completeActionError}>
                    <p className={classNames(css.actionError, css.completeActionErrorText)}>
                      {<FormattedMessage id="TransactionPanel.completeSaleFailed" />}
                    </p>
                  </div>
                )}
              </>
            );
          }}
        />
      )}
    </div>
  );
};

export default injectIntl(CompletionProcess);
